import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {Observable, EMPTY, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { User } from '../../../../common/user.model';
import { Logout } from '../actions/auth';
import { State } from '../reducers/auth';
import { ApiRoutes, ServerRoutes } from '../../../../common/api-routes';
import {LiteUserProfile, UserProfile} from '../../../../common/user-profile.model';
import { Project } from '../../../../common/project.model';
import {ClientConfig} from '../../../../common/client-config.model';

@Injectable()
export class AuthService {
  constructor(private http: HttpClient, private store: Store<State>) {}
  public retrieveUser(): Observable<UserProfile> {
    return this.http
      .get<UserProfile>(ApiRoutes.UserProfile, {responseType: 'json'})
      .pipe(
        map((userProfile: UserProfile) => {
          return {
            user: userProfile.user,
            projects: _.sortBy(userProfile.projects, 'projectName')
          };
        }),
      );
  }
  public retrieveUserAsPromise(): Promise<UserProfile> {
    return this.retrieveUser().toPromise();
  }
  public handleAuthError(error: HttpErrorResponse): Observable<UserProfile> {
    console.log(`Error: ${JSON.stringify(error, null, 2)}`);
    if (error.status === 401) {
      this.store.dispatch(Logout());
      return EMPTY;
    }
    throw new Error(`Auth Error: ${JSON.stringify(error, null, 2)}`);
  }

  public logout(): Observable<User> {
    window.location.href = ServerRoutes(ApiRoutes.Login);
    return EMPTY;
  }
  public retrieveProjects(): Observable<Project[]> {
    return this.http
      .get<Project[]>(ApiRoutes.Projects, {responseType: 'json'})
      .pipe();
  }
  public retrieveLiteUserProfile(projectId: string): Observable<LiteUserProfile> {
    return this.http
      .get<LiteUserProfile>(`${ApiRoutes.UserProfile}/projects/${projectId}`, { responseType: 'json'})
      .pipe();
  }
  public retrieveClientConfig(): Observable<ClientConfig> {
    return this.http
      .get<ClientConfig>(`${ApiRoutes.Settings}/clientConfig`, {responseType: 'json'})
      .pipe(
        catchError(error => of(undefined))
      );
  }
}
