import { Injectable } from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import { Store } from '@ngrx/store';
import {catchError, tap} from 'rxjs/operators';

import {RetrieveUnionFailed, RetrieveUnionSuccessful} from '../actions/lookup.action';
import {getState} from '../shared/utils';
import {selectProjectSettings} from '../auth/selectors';
import {ProjectSetting} from '../../../common/project-setting.model';
import {CMSDataService} from '../shared/data-services/cms-data.service';
import {FullUnion} from '../../../common/contract-rate.model';

@Injectable()
export class UnionResolver implements Resolve<FullUnion[]> {
  constructor( private _cmsDataService: CMSDataService, private _store: Store<any>) {}

  resolve(): Observable<FullUnion[]>|Promise<any>|any {
    const {clientId} = getState<ProjectSetting>(this._store, selectProjectSettings);
    return this._cmsDataService.RetrieveUnions(clientId).pipe(
      catchError(() => {
        this._store.dispatch(RetrieveUnionFailed());
        return of();
      }),
      tap((unions: FullUnion[]) => this._store.dispatch(RetrieveUnionSuccessful(unions)))
    );
  }
}
