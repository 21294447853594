import * as _ from 'lodash';
import {createFeatureSelector, createSelector} from '@ngrx/store';

import {UiState} from '../reducers/ui.reducer';
import {LoadCrew, LoadStartcards, Pages, Toast} from '../models/ui.model';
import {Department, DepartmentStartcards} from '../models/crew-department.model';
import * as fromManageColumn from '../shared/components/manage-column/manage-column.model';
import {AccountCodeSegmentConfig, SegmentType} from '../../../common/project-setting.model';
import {TimecardTypes} from '../../../common/timecard.model';
import {DailyTimeNew, IsSplitCoding} from '../../../common/daily-time.model';
import {selectDailyTimeEntries} from '../daily-time/selectors/sag/daily-time.selector';

const _getRealTabOrder = (state: UiState, relativeTabOrder: number[]) => {
  const tabOrderMap = _.get(state, 'tabOrderMap', []);
  return _.findIndex(tabOrderMap, (item) => {
    return item === relativeTabOrder;
  }) + 1; // tabIndex should not be zero based index
};

// TODO add unit tests for manage Column Related selectors
const _selectUiRootState = createFeatureSelector<UiState>('ui');
const _selectColumnConfigs = (state: UiState) => state && state.columnConfigs;
const _selectToasts = (state: UiState) => state && state.toasts;
const _getIsBrowsePageEmpty = (state: UiState) => state && state.isBrowsePageEmpty;
const _getIsWorkLocationPickerOpened = (state: UiState) => state && state.isWorkLocationPickerOpened;
const _selectWorkLocationPickerTargetEntity = (state: UiState) => state && state.workLocationPickerTargetEntity;
const _selectUiModal = (state: UiState) => state && state.modal;

export const selectActiveTimecardType = createSelector(
  _selectUiRootState,
  (state: UiState): TimecardTypes => state.activeTimecardType
);

export const _selectColumnGroupConfig = (tcGridSectionConfig, section: fromManageColumn.Sections): fromManageColumn.ColumnGroupConfig =>
  _.thru(_.get(tcGridSectionConfig, `${section}`, {}), (sectionConfig) => ({
    groupName: sectionConfig.groupName,
    section: sectionConfig.section,
    isSelected: sectionConfig.isVisible,
    isShowAllColumns: sectionConfig.isShowAllColumns,
    columns: _.orderBy(_.map(sectionConfig.columns, columnConfig => columnConfig), 'ordinal')})
);

const _selectSectionIsShowAll = (columnConfig, section: fromManageColumn.Sections): boolean => {
  return _.get(columnConfig, `${section}.isShowAllColumns`, false);
};

const _selectSectionIsHidden = (columnConfig, section: fromManageColumn.Sections): boolean => _.chain(columnConfig)
  .get(`${section}.columns`, {})
  .findKey((config: fromManageColumn.SectionColumnConfig) => config.isVisible)
  .thru(hiddenField => hiddenField === undefined)
  .value();

const _selectSectionIsVisible = (timeGridConfigs, section: fromManageColumn.Sections): boolean => _.chain(timeGridConfigs)
  .get(`${section}.columns`, {})
  .findKey((config: fromManageColumn.SectionColumnConfig) => !config.isVisible)
  .thru(hiddenField => hiddenField === undefined)
  .value();

const _selectAccountCodingColConfigs = (state: AccountCodeSegmentConfig[]) => state && _.map(state, (segment, index) => {
  const isVisible = segment.codingType === SegmentType.department;
  return {
    field: segment.header,
    header: segment.header,
    isVisible,
    isMandatory: false,
    ordinal: ++index
  };
});

export const _selectVisibleColumnCount = (gridConfig): number => _.chain(gridConfig)
  .flatMap(timeGridConfig => _.map(timeGridConfig.columns, (columnConfig) => columnConfig))
  .filter(config => config.isVisible)
  .thru(filteredArray => filteredArray.length)
  .value();

export const _selectSectionVisibility = (columnConfig, section): fromManageColumn.ColumnVisibilityMap => {
  const columns = _.get(columnConfig, `${section}.columns`, {});
  const isShowAll: boolean = _.get(columnConfig, `${section}.isShowAllColumns`, false);
  return _.mapValues(columns, (config) => config.isVisible || isShowAll );
};

const _selectSectionColumnVisibility = (columnConfig, section) => {
  const columns = _.get(columnConfig, `${section}.columns`, {});
  return _.mapValues(columns, (config) => config.isVisible);
};

const _selectSectionColumnsVisibility = (columnConfig, sectionsHiddenStatus, section) => {
  const columns = _.get(columnConfig, `${section}.columns`, {});
  const isShowAll: boolean = _.get(columnConfig, `${section}.isShowAllColumns`, false);
  const isSectionHidden: boolean = _.get(sectionsHiddenStatus, section, false);
  return _.mapValues(columns, (config) => config.isVisible || isShowAll && !isSectionHidden);
};

const _selectBrowsePageColumnSelection = (state: UiState) => state && _.get(state, 'browsePageRowGroupStatus', null);

const _selectDtsManageColumnForCoding = (dtsColumnConfig: fromManageColumn.ColumnGroupConfig): fromManageColumn.ColumnVisibilityMap => _
  .reduce(dtsColumnConfig.columns, (previous, current) => {
    previous[current.field] = current.isVisible;
    return previous;
  }, {});

export const selectIsWorkLocationPickerOpened = createSelector(
  _selectUiRootState,
  _getIsWorkLocationPickerOpened
);

export const selectWorkLocationPickerTargetEntity = createSelector(
  _selectUiRootState,
  _selectWorkLocationPickerTargetEntity
);

export const selectTabOrderMap = createSelector(
  _selectUiRootState,
  (state: UiState) => state ? state.tabOrderMap : []
);
export const selectRealTabOrder = (relativeTabOrder: number[]) => createSelector(
  _selectUiRootState,
  (state: UiState) => _getRealTabOrder(state, relativeTabOrder)
);

export const selectColumnConfigs = createSelector(
  _selectUiRootState,
  _selectColumnConfigs
);

export const selectNewTcGridConfig = createSelector(
  selectColumnConfigs,
  selectActiveTimecardType,
  (state: fromManageColumn.ColumnConfigStore, timecardType: TimecardTypes) => state && _.get(state, `${timecardType}.newTCTimeGrid`, null)
);

export const selectSummaryGridConfig = createSelector(
  selectColumnConfigs,
  selectActiveTimecardType,
  (state: fromManageColumn.ColumnConfigStore, timecardType: TimecardTypes) => state && _.get(state, `${timecardType}.newTCSummaryGrid`, null)
);
export const selectStartcardBrowseGridConfig = createSelector(
  selectColumnConfigs,
  (state: fromManageColumn.ColumnConfigStore) => state && _.get(state, `CREW.startcardBrowseGrid`, null)
);
export const selectPaymasterBatchBrowseGridConfig = createSelector(
  selectColumnConfigs,
  (state: fromManageColumn.ColumnConfigStore) => state && _.get(state, `CREW.paymasterBatchBrowseGrid`, null)
);
export const selectPaymasterBatchDetailsTcGridConfig = createSelector(
  selectColumnConfigs,
  (state: fromManageColumn.ColumnConfigStore) => state && _.get(state, `CREW.paymasterBatchDetailsTcGrid`, null)
);
export const selectTimecardBrowseGridConfig = createSelector(
  selectColumnConfigs,
  selectActiveTimecardType,
  (state: fromManageColumn.ColumnConfigStore, timecardType: TimecardTypes) => state && _.get(state, `${timecardType}.timecardBrowseGrid`)
);

export const selectApprovalBrowseGridConfig = createSelector(
  selectColumnConfigs,
  (state: fromManageColumn.ColumnConfigStore) => state && _.get(state, `CREW.approvalBrowseGrid`, null)
);

export const selectDailyTimeGridConfig = createSelector(
  selectColumnConfigs,
  selectActiveTimecardType,
  (state: fromManageColumn.ColumnConfigStore, timecardType: TimecardTypes) => state && _.get(state, `${timecardType}.dailyTimeGrid`, null)
);

export const selectSagDailyTimeGridConfig = createSelector(
  selectColumnConfigs,
  (state: fromManageColumn.ColumnConfigStore) => state && _.get(state, `SAG.sagDailyTimeGrid`, null)
);

export const selectDailyTimesGridConfig = (dailyTimeGrid: fromManageColumn.Grids) => createSelector(
  selectColumnConfigs,
  (state: fromManageColumn.ColumnConfigStore) => state && _.get(state, `SAG.${dailyTimeGrid}`)
);

export const selectSmartTimeUserGridConfig = createSelector(
  selectColumnConfigs,
  (state: fromManageColumn.ColumnConfigStore) => state && _.get(state, `CREW.smartTimeUserGrid`, null)
);

export const selectMobileUserGridConfig = createSelector(
  selectColumnConfigs,
  (state: fromManageColumn.ColumnConfigStore) => state && _.get(state, `CREW.mobileUserGrid`, null)
);

export const selectToasts = createSelector(
  _selectUiRootState,
  _selectToasts
);
 const _selectPageToasts = (toasts: Toast[], page: Pages) => _.filter(toasts, (toast) => toast.page === page);
// selector factory functions for all sections
const _getPageToastSelector = (page: Pages) => createSelector(
  selectToasts,
  toasts => _selectPageToasts(toasts, page)
);

export const selectIsBrowsePageEmpty = createSelector(
  _selectUiRootState,
  _getIsBrowsePageEmpty
);

const _getTimeGridGroupConfigSelector = (section: fromManageColumn.Sections) => createSelector(
  selectNewTcGridConfig,
  timeGridConfig => _selectColumnGroupConfig(timeGridConfig, section)
);

const _getDtsGridGroupConfigSelector = (section: fromManageColumn.Sections) => createSelector(
  selectDailyTimeGridConfig,
  dtGridConfig => _selectColumnGroupConfig(dtGridConfig, section)
);

const _getSagDtsGridGroupConfigSelector = (section: fromManageColumn.Sections) => createSelector(
  selectSagDailyTimeGridConfig,
  dtGridConfig => _selectColumnGroupConfig(dtGridConfig, section)
);

const _selectApprovalAccountCodingGroupConfig  = _getTimeGridGroupConfigSelector(fromManageColumn.Sections.accountCoding);


const _getSummaryGroupConfigSelector = (section: fromManageColumn.Sections) => createSelector(
  selectSummaryGridConfig,
  summaryGridConfig => _selectColumnGroupConfig(summaryGridConfig, section)
);

const _getStartcardBrowseGroupConfigSelector = (section: fromManageColumn.Sections) => createSelector(
  selectStartcardBrowseGridConfig,
  startcardBrowseGridConfig => _selectColumnGroupConfig(startcardBrowseGridConfig, section)
);
const _getPaymasterBatchBrowseGroupConfigSelector = (section: fromManageColumn.Sections) => createSelector(
  selectPaymasterBatchBrowseGridConfig,
  paymasterBatchBrowseGridConfig => _selectColumnGroupConfig(paymasterBatchBrowseGridConfig, section)
);
const _getPaymasterBatchDetailsGroupConfigSelector = (section: fromManageColumn.Sections) => createSelector(
  selectPaymasterBatchDetailsTcGridConfig,
  paymasterBatchDetailsGridConfig => _selectColumnGroupConfig(paymasterBatchDetailsGridConfig, section)
);
const _getTimecardBrowseGroupConfigSelector = (section: fromManageColumn.Sections) => createSelector(
  selectTimecardBrowseGridConfig,
  timecardBrowseGridConfig => _selectColumnGroupConfig(timecardBrowseGridConfig, section)
);

const _getApprovalBrowseGroupConfigSelector = (section: fromManageColumn.Sections) => createSelector(
  selectApprovalBrowseGridConfig,
  approvalBrowseConfig => _selectColumnGroupConfig(approvalBrowseConfig, section)
);

const _selectAccountCodingGroupConfig = _getApprovalBrowseGroupConfigSelector(fromManageColumn.Sections.coding);

const _getSmartTimeUserGroupConfigSelector = (section: fromManageColumn.Sections) => createSelector(
  selectSmartTimeUserGridConfig,
  userManagementGridConfig => _selectColumnGroupConfig(userManagementGridConfig, section)
);

const _getMobileUserGroupConfigSelector = (section: fromManageColumn.Sections) => createSelector(
  selectMobileUserGridConfig,
  userMobileManagementGridConfig => _selectColumnGroupConfig(userMobileManagementGridConfig, section)
);

const _getTimeGridIsShowAllSelector = (section: fromManageColumn.Sections) => createSelector(
  selectNewTcGridConfig,
  timeGridConfig => _selectSectionIsShowAll(timeGridConfig, section)
);

const _getSummaryIsShowAllSelector = (section: fromManageColumn.Sections) => createSelector(
  selectSummaryGridConfig,
  summaryGridConf => _selectSectionIsShowAll(summaryGridConf, section)
);

const _getApprovalIsShowAllSelector = (section: fromManageColumn.Sections) => createSelector(
  selectApprovalBrowseGridConfig,
  approvalGridConf => _selectSectionIsShowAll(approvalGridConf, section)
);
const selectCodingIsShowAll = _getApprovalIsShowAllSelector(fromManageColumn.Sections.coding);

const _getDTSectionsIsShowAllSelector = (section: fromManageColumn.Sections) => createSelector(
  selectDailyTimeGridConfig,
  dtSectionConf => _selectSectionIsShowAll(dtSectionConf, section)
);
const _getSagDTSectionsIsShowAllSelector = (section: fromManageColumn.Sections) => createSelector(
  selectSagDailyTimeGridConfig,
  dtSectionConf => _selectSectionIsShowAll(dtSectionConf, section)
);

const _getTimeGridIsHiddenSelector = (section: fromManageColumn.Sections) => createSelector(
  selectNewTcGridConfig,
  timeGridConfig => _selectSectionIsHidden(timeGridConfig, section)
);

const _getSummaryIsHiddenSelector = (section: fromManageColumn.Sections) => createSelector(
  selectSummaryGridConfig,
  summaryGridConf => _selectSectionIsHidden(summaryGridConf, section)
);

const _getApprovalSummaryIsHiddenSelector = (section: fromManageColumn.Sections) => createSelector(
  selectApprovalBrowseGridConfig,
  approvalGriConf => _selectSectionIsHidden(approvalGriConf, section)
);

const _selectCodingIsHidden = _getApprovalSummaryIsHiddenSelector(fromManageColumn.Sections.coding);

const _getDtGridIsHiddenSelector = (section: fromManageColumn.Sections) => createSelector(
  selectDailyTimeGridConfig,
  dtsGridConfig => _selectSectionIsHidden(dtsGridConfig, section)
);
const _getSagDtGridIsHiddenSelector = (section: fromManageColumn.Sections) => createSelector(
  selectSagDailyTimeGridConfig,
  dtsGridConfig => _selectSectionIsHidden(dtsGridConfig, section)
);

const _getDtGridIsVisibleSelector = (section: fromManageColumn.Sections) => createSelector(
  selectDailyTimeGridConfig,
  dtGridConfig => _selectSectionIsVisible(dtGridConfig, section)
);

const _getSagDtGridIsVisibleSelector = (section: fromManageColumn.Sections) => createSelector(
  selectSagDailyTimeGridConfig,
  dtGridConfig => _selectSectionIsVisible(dtGridConfig, section)
);

const _getTimeGridIsVisibleSelector = (section: fromManageColumn.Sections) => createSelector(
  selectNewTcGridConfig,
  timeGridConfig => _selectSectionIsVisible(timeGridConfig, section)
);

const _getSummaryIsVisibleSelector = (section: fromManageColumn.Sections) => createSelector(
  selectSummaryGridConfig,
  summaryGridConf => _selectSectionIsVisible(summaryGridConf, section)
);

const _getApprovalIsVisibleSelector = (section: fromManageColumn.Sections) => createSelector(
  selectApprovalBrowseGridConfig,
  approvalGridConf => _selectSectionIsVisible(approvalGridConf, section)
);

export const _selectCodingIsVisible = _getApprovalIsVisibleSelector(fromManageColumn.Sections.coding);

// manage column group configs to draw checkboxes in popover:

export const selectCommonPunchGroupConfig  = _getTimeGridGroupConfigSelector(fromManageColumn.Sections.commonPunch);
export const selectReRateGroupConfig  = _getTimeGridGroupConfigSelector(fromManageColumn.Sections.reRate);
export const selectShiftDetailsGroupConfig = _getTimeGridGroupConfigSelector(fromManageColumn.Sections.details);
export const selectTimeGridGroupConfig = createSelector(
  selectCommonPunchGroupConfig,
  selectReRateGroupConfig,
  _selectApprovalAccountCodingGroupConfig,
  selectShiftDetailsGroupConfig,
  (commonPunchConfig, reRateConfig, accountCodingConfig, shiftDetailsConfig) =>
    [commonPunchConfig, reRateConfig, accountCodingConfig, shiftDetailsConfig]
);

export const selectSummaryBasicsGroupConfig = _getSummaryGroupConfigSelector(fromManageColumn.Sections.summaryBasics);
export const selectSummaryJobDetailGroupConfig = _getSummaryGroupConfigSelector(fromManageColumn.Sections.summaryJobDetail);
export const selectSummaryAccountCodingGroupConfig = _getSummaryGroupConfigSelector(fromManageColumn.Sections.summaryAccountCode);
export const selectSummaryPayDetailsCodingGroupConfig = _getSummaryGroupConfigSelector(fromManageColumn.Sections.summaryPayDetail);
export const selectSummaryGridGroupConfig = createSelector(
  selectSummaryBasicsGroupConfig,
  selectSummaryAccountCodingGroupConfig,
  selectSummaryJobDetailGroupConfig,
  selectSummaryPayDetailsCodingGroupConfig,
  (summaryBasicsConfig, summaryAccountCodeConfig, summaryJobConfig, summaryPayDetailConfig) =>
    [summaryBasicsConfig, summaryAccountCodeConfig, summaryJobConfig, summaryPayDetailConfig]
);

export const selectStartcardBrowseGroupConfig = _getStartcardBrowseGroupConfigSelector(fromManageColumn.Sections.all);
export const selectStartcardBrowseAllGroupConfig = createSelector(
  selectStartcardBrowseGroupConfig,
  (config) => ([config])
);

export const selectPaymasterBatchBrowseGroupConfig = _getPaymasterBatchBrowseGroupConfigSelector(fromManageColumn.Sections.all);
export const selectPaymasterBatchBrowseAllGroupConfig = createSelector(
  selectPaymasterBatchBrowseGroupConfig,
  (config) => ([config])
);
export const selectPaymasterBatchDetailsGroupConfig = _getPaymasterBatchDetailsGroupConfigSelector(fromManageColumn.Sections.all);
export const selectPaymasterBatchDetailsTcAllGroupConfig = createSelector(
  selectPaymasterBatchDetailsGroupConfig,
  (config) => ([config])
);
export const selectTimecardBrowseGroupConfig = _getTimecardBrowseGroupConfigSelector(fromManageColumn.Sections.all);
export const selectTimecardBrowseAllGroupConfig = createSelector(
  selectTimecardBrowseGroupConfig,
  (config) => ([config])
);

export const selectCrewGroupConfig  = _getApprovalBrowseGroupConfigSelector(fromManageColumn.Sections.crew);
export const selectDetailsGroupConfig  = _getApprovalBrowseGroupConfigSelector(fromManageColumn.Sections.details);
export const selectSummaryGroupConfig  = _getApprovalBrowseGroupConfigSelector(fromManageColumn.Sections.summary);
export const selectApprovalBrowseAllGroupConfig = createSelector(
  selectCrewGroupConfig,
  selectDetailsGroupConfig,
  selectSummaryGroupConfig,
  _selectAccountCodingGroupConfig,
  (crewConfig, detailsCodingConfig, summaryConfig, codingConfig) =>
    [crewConfig, codingConfig, detailsCodingConfig, summaryConfig]
);

export const selectSmartTimeUserGroupConfig = _getSmartTimeUserGroupConfigSelector(fromManageColumn.Sections.all);
export const selectSmartTimeUserAllGroupConfig = createSelector(
  selectSmartTimeUserGroupConfig,
  (config) => ([config])
);

export const selectMobileUserGroupConfig = _getMobileUserGroupConfigSelector(fromManageColumn.Sections.all);
export const selectMobileUserAllGroupConfig = createSelector(
  selectMobileUserGroupConfig,
  (config) => ([config])
);

export const selectTimesSectionIsShowAll = (section: fromManageColumn.Sections) => createSelector(
  selectNewTcGridConfig,
  (timeGridConfig): boolean => _selectSectionIsShowAll(timeGridConfig, section)
);
export const selectIsTimeGridSectionHidden = (section: fromManageColumn.Sections) => createSelector(
  selectNewTcGridConfig,
  (timeGridConfig): boolean => _selectSectionIsHidden(timeGridConfig, section)
);
export const selectCommonPunchIsShowAll = _getTimeGridIsShowAllSelector(fromManageColumn.Sections.commonPunch);
export const selectReRateIsShowAll = _getTimeGridIsShowAllSelector(fromManageColumn.Sections.reRate);
export const selectShiftDetailsIsShowAll = _getTimeGridIsShowAllSelector(fromManageColumn.Sections.details);
export const selectAccountCodingIsShowAll = _getTimeGridIsShowAllSelector(fromManageColumn.Sections.accountCoding);
export const selectBreakageIsShowAll = _getTimeGridIsShowAllSelector(fromManageColumn.Sections.breakages);
export const selectTimeGridSectionIsShowAll = createSelector(
  selectCommonPunchIsShowAll,
  selectReRateIsShowAll,
  selectShiftDetailsIsShowAll,
  selectAccountCodingIsShowAll,
  selectBreakageIsShowAll,
  (isCommonPunchShowAll, isReRateShowAll, isShiftDetailsShowAll, isAccountCodingShowAll, isBreakageShowAll) =>
    ({isCommonPunchShowAll, isReRateShowAll, isShiftDetailsShowAll, isAccountCodingShowAll, isBreakageShowAll})
);

export const selectSummaryBasicsIsShowAll = _getSummaryIsShowAllSelector(fromManageColumn.Sections.summaryBasics);
export const selectSummaryJobIsShowAll = _getSummaryIsShowAllSelector(fromManageColumn.Sections.summaryJobDetail);
export const selectSummaryPayDetailsIsShowAll = _getSummaryIsShowAllSelector(fromManageColumn.Sections.summaryPayDetail);
export const selectSummaryAccountCodingIsShowAll = _getSummaryIsShowAllSelector(fromManageColumn.Sections.summaryAccountCode);
export const selectSummarySectionIsShowAll = createSelector(
  selectSummaryBasicsIsShowAll,
  selectSummaryJobIsShowAll,
  selectSummaryPayDetailsIsShowAll,
  selectSummaryAccountCodingIsShowAll,
  (isSummaryBasicsShowAll, isSummaryJobShowAll, isSummaryPayDetailsShowAll, isSummaryAccountCodingShowAll) =>
    ({isSummaryBasicsShowAll, isSummaryJobShowAll, isSummaryPayDetailsShowAll, isSummaryAccountCodingShowAll})
);

export const selectCrewIsShowAll = _getApprovalIsShowAllSelector(fromManageColumn.Sections.crew);
export const selectDetailsIsShowAll = _getApprovalIsShowAllSelector(fromManageColumn.Sections.details);
export const selectSummaryIsShowAll = _getApprovalIsShowAllSelector(fromManageColumn.Sections.summary);
export const selectApprovalGridSectionIsShowAll = createSelector(
  selectCrewIsShowAll,
  selectDetailsIsShowAll,
  selectSummaryIsShowAll,
  selectCodingIsShowAll,
  (isCrewShowAll, isDetailsShowAll, isSummaryShowAll, isCodingShowAll) =>
    ({isCrewShowAll, isDetailsShowAll, isSummaryShowAll, isCodingShowAll})
);

export const selectCommonPunchIsHidden = _getTimeGridIsHiddenSelector(fromManageColumn.Sections.commonPunch);
export const selectReRateIsHidden = _getTimeGridIsHiddenSelector(fromManageColumn.Sections.reRate);
export const selectShiftDetailIsHidden = _getTimeGridIsHiddenSelector(fromManageColumn.Sections.details);
export const selectAccountCodingIsHidden = _getTimeGridIsHiddenSelector(fromManageColumn.Sections.accountCoding);
export const selectTimeGridSectionIsHidden = createSelector(
  selectCommonPunchIsHidden,
  selectReRateIsHidden,
  selectShiftDetailIsHidden,
  selectAccountCodingIsHidden,
  (isCommonPunchHidden, isReRateHidden, isShiftDetailsHidden, isAccountCodingHidden) =>
    ({isCommonPunchHidden, isReRateHidden, isShiftDetailsHidden, isAccountCodingHidden})
);

export const selectSummaryBasicsIsHidden = _getSummaryIsHiddenSelector(fromManageColumn.Sections.summaryBasics);
export const selectJobDetailsIsHidden = _getSummaryIsHiddenSelector(fromManageColumn.Sections.summaryJobDetail);
export const selectPayDetailIsHidden = _getSummaryIsHiddenSelector(fromManageColumn.Sections.summaryPayDetail);
export const selectSummaryAccountCodingIsHidden = _getSummaryIsHiddenSelector(fromManageColumn.Sections.summaryAccountCode);
export const selectSummaryPayDetailsIsHidden = _getSummaryIsHiddenSelector(fromManageColumn.Sections.summaryPayDetail);
export const selectSummarySectionIsHidden = createSelector(
  selectSummaryBasicsIsHidden,
  selectJobDetailsIsHidden,
  selectPayDetailIsHidden,
  selectSummaryAccountCodingIsHidden,
  selectSummaryPayDetailsIsHidden,
  (isSummaryBasicsHidden, isJobDetailsHidden, isPayDetailsHidden, isSummaryAccountCodingHidden, isSummaryPayDetailsHidden) =>
    ({isSummaryBasicsHidden, isJobDetailsHidden, isPayDetailsHidden, isSummaryAccountCodingHidden, isSummaryPayDetailsHidden})
);

export const selectCrewIsHidden = _getApprovalSummaryIsHiddenSelector(fromManageColumn.Sections.crew);
export const selectDetailsIsHidden = _getApprovalSummaryIsHiddenSelector(fromManageColumn.Sections.details);
export const selectSummaryIsHidden = _getApprovalSummaryIsHiddenSelector(fromManageColumn.Sections.summary);
export const selectApprovalSectionsHiddenStatus = createSelector(
  selectCrewIsHidden,
  selectDetailsIsHidden,
  selectSummaryIsHidden,
  _selectCodingIsHidden,
  (crew, details, summary, coding) => ({crew, details, summary, coding})
);

export const selectCommonPunchIsVisible = _getTimeGridIsVisibleSelector(fromManageColumn.Sections.commonPunch);
export const selectReRateIsVisible = _getTimeGridIsVisibleSelector(fromManageColumn.Sections.reRate);
export const selectShiftDetailIsVisible = _getTimeGridIsVisibleSelector(fromManageColumn.Sections.details);
export const selectAccountCodingIsVisible = _getTimeGridIsVisibleSelector(fromManageColumn.Sections.accountCoding);
export const selectTimeGridAllSectionsVisibility = createSelector(
  selectCommonPunchIsVisible,
  selectReRateIsVisible,
  selectShiftDetailIsVisible,
  selectAccountCodingIsVisible,
  (isCommonPunchVisible, isReRateVisible, isShiftDetailVisible, isAccountCodingVisible) =>
    ({
      [fromManageColumn.Sections.commonPunch]: isCommonPunchVisible,
      [fromManageColumn.Sections.reRate]: isReRateVisible,
      [fromManageColumn.Sections.details]: isShiftDetailVisible,
      [fromManageColumn.Sections.accountCoding]: isAccountCodingVisible
    })
);

export const selectSummaryBasicsIsVisible = _getSummaryIsVisibleSelector(fromManageColumn.Sections.summaryBasics);
export const selectJobDetailsIsVisible = _getSummaryIsVisibleSelector(fromManageColumn.Sections.summaryJobDetail);
export const selectPayDetailIsVisible = _getSummaryIsVisibleSelector(fromManageColumn.Sections.summaryPayDetail);
export const selectSummaryAccountCodingIsVisible = _getSummaryIsVisibleSelector(fromManageColumn.Sections.summaryAccountCode);
export const selectSummaryAllSectionVisibility = createSelector(
  selectSummaryBasicsIsVisible,
  selectJobDetailsIsVisible,
  selectPayDetailIsVisible,
  selectSummaryAccountCodingIsVisible,
  (isSummaryBasicsVisible, isJobDetailsVisible, isPayDetailsVisible, isSummaryAccountCodingVisible) =>
    ({
      [fromManageColumn.Sections.summaryBasics]: isSummaryBasicsVisible,
      [fromManageColumn.Sections.summaryJobDetail]: isJobDetailsVisible,
      [fromManageColumn.Sections.summaryPayDetail]: isPayDetailsVisible,
      [fromManageColumn.Sections.summaryAccountCode]: isSummaryAccountCodingVisible
    })
);

export const selectCrewIsVisible = _getApprovalIsVisibleSelector(fromManageColumn.Sections.crew);
export const selectDetailsIsVisible = _getApprovalIsVisibleSelector(fromManageColumn.Sections.details);
export const selectSummaryIsVisible = _getApprovalIsVisibleSelector(fromManageColumn.Sections.summary);
export const selectApprovalBrowseAllSectionsVisibility = createSelector(
  selectCrewIsVisible,
  selectDetailsIsVisible,
  selectSummaryIsVisible,
  _selectCodingIsVisible,
  (isCrewVisible, isDetailsVisible, isSummaryVisible, isCodingVisible) =>
    ({
      [fromManageColumn.Sections.crew]: isCrewVisible,
      [fromManageColumn.Sections.coding]: isCodingVisible,
      [fromManageColumn.Sections.details]: isDetailsVisible,
      [fromManageColumn.Sections.summary]: isSummaryVisible
    })
);

export const selectTimeGridVisibleColumnCount = createSelector(
  selectNewTcGridConfig,
  _selectVisibleColumnCount
);
export const selectDtGridVisibleColumnCount = createSelector(
  selectDailyTimeGridConfig,
  _selectVisibleColumnCount
);
export const selectSagDtGridVisibleColumnCount = createSelector(
  selectSagDailyTimeGridConfig,
  _selectVisibleColumnCount
);
export const selectSummaryGridVisibleColumnCount = createSelector(
  selectSummaryGridConfig,
  (summary) => _selectVisibleColumnCount(summary)
);
export const selectStartcardBrowseVisibleColumnCount = createSelector(
  selectStartcardBrowseGridConfig,
  _selectVisibleColumnCount
);
export const selectPaymasterBrowseVisibleColumnCount = createSelector(
  selectPaymasterBatchBrowseGridConfig,
  _selectVisibleColumnCount
);
export const selectPaymasterBatchDetailsTcVisibleColumnCount = createSelector(
  selectPaymasterBatchDetailsTcGridConfig,
  _selectVisibleColumnCount
);
export const selectTimecardBrowseVisibleColumnCount = createSelector(
  selectTimecardBrowseGridConfig,
  _selectVisibleColumnCount
);

export const selectApprovalBrowseGridVisibleCount = createSelector(
  selectApprovalBrowseGridConfig,
  _selectVisibleColumnCount
);

export const selectSmartTimeUserVisibleColumnCount = createSelector(
  selectSmartTimeUserGridConfig,
  _selectVisibleColumnCount
);

export const selectMobileUserVisibleColumnCount = createSelector(
  selectMobileUserGridConfig,
  _selectVisibleColumnCount
);

export const selectTimeGridSectionVisibility = (section: fromManageColumn.Sections) => createSelector(
  selectNewTcGridConfig,
  columnConfig => _selectSectionVisibility(columnConfig, section)
);

export const selectTimeGridSectionColumnVisibility = (section: fromManageColumn.Sections) => createSelector(
  selectNewTcGridConfig,
  columnConfig => _selectSectionColumnVisibility(columnConfig, section)
);

export const selectSummarySectionVisibility = (section: fromManageColumn.Sections) => createSelector(
  selectSummaryGridConfig,
  columnConfig => _selectSectionVisibility(columnConfig, section)
);

export const selectStartcardBrowseSectionVisibility = (section: fromManageColumn.Sections) => createSelector(
  selectStartcardBrowseGridConfig,
  columnConfig => _selectSectionVisibility(columnConfig, section)
);

export const selectPaymasterBrowseSectionVisibility = (section: fromManageColumn.Sections) => createSelector(
  selectPaymasterBatchBrowseGridConfig,
  columnConfig => _selectSectionVisibility(columnConfig, section)
);
export const selectBatchDetailsTcSectionVisibility = (section: fromManageColumn.Sections) => createSelector(
  selectPaymasterBatchDetailsTcGridConfig,
  columnConfig => _selectSectionVisibility(columnConfig, section)
);
export const selectTimecardBrowseSectionVisibility = (section: fromManageColumn.Sections) => createSelector(
  selectTimecardBrowseGridConfig,
  columnConfig => _selectSectionVisibility(columnConfig, section)
);
export const selectTemplateSetStatus = createSelector(
  _selectUiRootState,
  (state: UiState) => state ? state.isTimecardTemplateAvailable : false
);

export const selectApprovalsBrowseSectionColsVisibility = (section: fromManageColumn.Sections) => createSelector(
  selectApprovalBrowseGridConfig,
  selectApprovalSectionsHiddenStatus,
  (columnConfig, sectionsHiddenStatus) => _selectSectionColumnsVisibility(columnConfig, sectionsHiddenStatus, section)
);

export const selectSmartTimeUserSectionVisibility = (section: fromManageColumn.Sections) => createSelector(
  selectSmartTimeUserGridConfig,
  columnConfig => _selectSectionVisibility(columnConfig, section)
);
export const selectMobileUserSectionVisibility = (section: fromManageColumn.Sections) => createSelector(
  selectMobileUserGridConfig,
  columnConfig => _selectSectionVisibility(columnConfig, section)
);
export const selectTimecardBrowseToasts = _getPageToastSelector(Pages.TimecardBrowse);
export const selectBatchBrowseToasts = _getPageToastSelector(Pages.BatchBrowse);
export const selectBatchDetailToasts = _getPageToastSelector(Pages.BatchDetail);
export const selectTimecardEntryToasts = _getPageToastSelector(Pages.TimecardEntry);
export const selectTimecardTemplateToasts = _getPageToastSelector(Pages.TimecardTemplate);
export const selectProjectSettingsToasts = _getPageToastSelector(Pages.ProjectSettings);
export const selectApprovalBrowseToasts = _getPageToastSelector(Pages.ApprovalBrowse);
export const selectDayCostReportToasts = _getPageToastSelector(Pages.DayCostReportPage);
export const selectStartcardBrowseToasts = _getPageToastSelector(Pages.StartcardBrowse);
export const selectStartcardDetailsToasts = _getPageToastSelector(Pages.StartcardDetails);
export const selectUserBrowseToasts = _getPageToastSelector(Pages.UserManagementBrowse);
export const selectPaymasterDetailsToasts = _getPageToastSelector(Pages.PaymasterBatchDetailsPage);
export const selectDailyTimeToasts = _getPageToastSelector(Pages.DailyTime);

export const selectBrowsePageColumnSelection = createSelector(
  _selectUiRootState,
  _selectBrowsePageColumnSelection
);

export const selectDtCrewIsHidden = _getDtGridIsHiddenSelector(fromManageColumn.Sections.crew);
export const selectDtDailyTimesIsHidden = _getDtGridIsHiddenSelector(fromManageColumn.Sections.dailyTimes);
export const selectDtAccountCodingIsHidden = _getDtGridIsHiddenSelector(fromManageColumn.Sections.accountCoding);
export const selectDtRerateIsHidden = _getDtGridIsHiddenSelector(fromManageColumn.Sections.reRate);
export const selectDtDetailsIsHidden = _getDtGridIsHiddenSelector(fromManageColumn.Sections.details);
export const selectDtSectionsHiddenStatus = createSelector(
  selectDtCrewIsHidden,
  selectDtDailyTimesIsHidden,
  selectDtAccountCodingIsHidden,
  selectDtRerateIsHidden,
  selectDtDetailsIsHidden,
  (crew, dailyTimes, accountCoding, reRate, details) =>
    ({crew, dailyTimes, accountCoding, reRate, details})
);

export const selectDtSectionColumnsVisibility = (section: fromManageColumn.Sections) => createSelector(
  selectDailyTimeGridConfig,
  selectDtSectionsHiddenStatus,
  (columnConfig, sectionsHiddenStatus) => _selectSectionColumnsVisibility(columnConfig, sectionsHiddenStatus, section)
);

export const selectSagDtSectionColumnsVisibility = (section: fromManageColumn.Sections) => createSelector(
  selectSagDailyTimeGridConfig,
  selectSagDtSectionsHiddenStatus,
  (columnConfig, sectionsHiddenStatus) => _selectSectionColumnsVisibility(columnConfig, sectionsHiddenStatus, section)
);

export const selectDtCrewIsVisible = _getDtGridIsVisibleSelector(fromManageColumn.Sections.crew);
export const selectDtDailyTimesIsVisible = _getDtGridIsVisibleSelector(fromManageColumn.Sections.dailyTimes);
export const selectDtAccountCodingIsVisible = _getDtGridIsVisibleSelector(fromManageColumn.Sections.accountCoding);
export const selectDtRerateIsVisible = _getDtGridIsVisibleSelector(fromManageColumn.Sections.reRate);
export const selectDtDetailsIsVisible = _getDtGridIsVisibleSelector(fromManageColumn.Sections.details);
export const selectDtGridAllSectionsVisibility = createSelector(
  selectDtCrewIsVisible,
  selectDtDailyTimesIsVisible,
  selectDtAccountCodingIsVisible,
  selectDtRerateIsVisible,
  selectDtDetailsIsVisible,
  (isCrewVisible, isDailyTimesVisible, isAccCodingVisible, isRerateVisible, isDetailsVisible) =>
    ({
      [fromManageColumn.Sections.crew]: isCrewVisible,
      [fromManageColumn.Sections.dailyTimes]: isDailyTimesVisible,
      [fromManageColumn.Sections.accountCoding]: isAccCodingVisible,
      [fromManageColumn.Sections.reRate]: isRerateVisible,
      [fromManageColumn.Sections.details]: isDetailsVisible
    })
);

export const selectDtCrewGroupConfig  = _getDtsGridGroupConfigSelector(fromManageColumn.Sections.crew);
export const selectDtDailytimesGroupConfig  = _getDtsGridGroupConfigSelector(fromManageColumn.Sections.dailyTimes);
export const selectDtAccountCodingGroupConfig  = _getDtsGridGroupConfigSelector(fromManageColumn.Sections.accountCoding);
export const selectDtReRateGroupConfig  = _getDtsGridGroupConfigSelector(fromManageColumn.Sections.reRate);
export const selectDtDetailsGroupConfig = _getDtsGridGroupConfigSelector(fromManageColumn.Sections.details);
export const selectDtGridGroupConfig = createSelector(
  selectDtCrewGroupConfig,
  selectDtDailytimesGroupConfig,
  selectDtAccountCodingGroupConfig,
  selectDtReRateGroupConfig,
  selectDtDetailsGroupConfig,
  (crewConfig, dailyTimesConfig, accountCodingConfig, reRateConfig, detailsConfig) =>
    [crewConfig, dailyTimesConfig, reRateConfig, accountCodingConfig, detailsConfig]
);

// SAG DTS

export const selectSagDtPerformerIsHidden = _getSagDtGridIsHiddenSelector(fromManageColumn.Sections.startcardInfo);
export const selectSagDtDailyTimesIsHidden = _getSagDtGridIsHiddenSelector(fromManageColumn.Sections.dailyTimes);
export const selectSagDtAccountCodingIsHidden = _getSagDtGridIsHiddenSelector(fromManageColumn.Sections.accountCoding);
export const selectSagDtRerateIsHidden = _getSagDtGridIsHiddenSelector(fromManageColumn.Sections.reRate);
export const selectSagDtDetailsIsHidden = _getSagDtGridIsHiddenSelector(fromManageColumn.Sections.details);
export const selectSagDtSectionsHiddenStatus = createSelector(
  selectSagDtPerformerIsHidden,
  selectSagDtDailyTimesIsHidden,
  selectSagDtAccountCodingIsHidden,
  selectSagDtRerateIsHidden,
  selectSagDtDetailsIsHidden,
  (performer, dailyTimes, accountCoding, reRate, details) => ({performer, dailyTimes, accountCoding, reRate, details})
);

export const selectSagDtPerformerIsVisible = _getSagDtGridIsVisibleSelector(fromManageColumn.Sections.startcardInfo);
export const selectSagDtDailyTimesIsVisible = _getSagDtGridIsVisibleSelector(fromManageColumn.Sections.dailyTimes);
export const selectSagDtAccountCodingIsVisible = _getSagDtGridIsVisibleSelector(fromManageColumn.Sections.accountCoding);
export const selectSagDtRerateIsVisible = _getSagDtGridIsVisibleSelector(fromManageColumn.Sections.reRate);
export const selectSagDtDetailsIsVisible = _getSagDtGridIsVisibleSelector(fromManageColumn.Sections.details);
export const selectSagDtGridAllSectionsVisibility = createSelector(
  selectSagDtPerformerIsVisible,
  selectSagDtDailyTimesIsVisible,
  selectSagDtAccountCodingIsVisible,
  selectSagDtRerateIsVisible,
  selectSagDtDetailsIsVisible,
  (isPerformerVisible, isDailyTimesVisible, isAccCodingVisible, isRerateVisible, isDetailsVisible) =>
    ({
      [fromManageColumn.Sections.startcardInfo]: isPerformerVisible,
      [fromManageColumn.Sections.dailyTimes]: isDailyTimesVisible,
      [fromManageColumn.Sections.accountCoding]: isAccCodingVisible,
      [fromManageColumn.Sections.reRate]: isRerateVisible,
      [fromManageColumn.Sections.details]: isDetailsVisible
    })
);

export const selectSagDtPerformerGroupConfig  = _getSagDtsGridGroupConfigSelector(fromManageColumn.Sections.startcardInfo);
export const selectSagDtDailyTimesGroupConfig  = _getSagDtsGridGroupConfigSelector(fromManageColumn.Sections.dailyTimes);
export const selectSagDtAccountCodingGroupConfig  = _getSagDtsGridGroupConfigSelector(fromManageColumn.Sections.accountCoding);
export const selectSagDtReRateGroupConfig  = _getSagDtsGridGroupConfigSelector(fromManageColumn.Sections.reRate);
export const selectSagDtDetailsGroupConfig = _getSagDtsGridGroupConfigSelector(fromManageColumn.Sections.details);
export const selectSagDtGridGroupConfig = createSelector(
  selectSagDtPerformerGroupConfig,
  selectSagDtDailyTimesGroupConfig,
  selectSagDtAccountCodingGroupConfig,
  selectSagDtReRateGroupConfig,
  selectSagDtDetailsGroupConfig,
  (performerConfig, dailyTimesConfig, accountCodingConfig, reRateConfig, detailsConfig) =>
    [performerConfig, dailyTimesConfig, reRateConfig, accountCodingConfig, detailsConfig]
);

export const selectSagDTPerformerIsShowAll = _getSagDTSectionsIsShowAllSelector(fromManageColumn.Sections.startcardInfo);
export const selectSagDTDailyTimesIsShowAll = _getSagDTSectionsIsShowAllSelector(fromManageColumn.Sections.dailyTimes);
export const selectSagDTCodingIsShowAll = _getSagDTSectionsIsShowAllSelector(fromManageColumn.Sections.accountCoding);
export const selectSagDTRerateIsShowAll = _getSagDTSectionsIsShowAllSelector(fromManageColumn.Sections.reRate);
export const selectSagDTDetailsIsShowAll = _getSagDTSectionsIsShowAllSelector(fromManageColumn.Sections.details);
export const selectSagDTGridSectionIsShowAll = createSelector(
  selectSagDTPerformerIsShowAll,
  selectSagDTDailyTimesIsShowAll,
  selectSagDTCodingIsShowAll,
  selectSagDTRerateIsShowAll,
  selectSagDTDetailsIsShowAll,
  (isPerformerShowAll, isDailyTimesShowAll, isCodingShowAll, isRerateShowAll, isDetailsShowAll) =>
    ({
      [fromManageColumn.Sections.startcardInfo]: isPerformerShowAll,
      [fromManageColumn.Sections.dailyTimes]: isDailyTimesShowAll,
      [fromManageColumn.Sections.accountCoding]: isCodingShowAll,
      [fromManageColumn.Sections.reRate]: isRerateShowAll,
      [fromManageColumn.Sections.details]: isDetailsShowAll
    })
);

export const selectDTCrewIsShowAll = _getDTSectionsIsShowAllSelector(fromManageColumn.Sections.crew);
export const selectDTDailyTimesIsShowAll = _getDTSectionsIsShowAllSelector(fromManageColumn.Sections.dailyTimes);
export const selectDTCodingIsShowAll = _getDTSectionsIsShowAllSelector(fromManageColumn.Sections.accountCoding);
export const selectDTRerateIsShowAll = _getDTSectionsIsShowAllSelector(fromManageColumn.Sections.reRate);
export const selectDTDetailsIsShowAll = _getDTSectionsIsShowAllSelector(fromManageColumn.Sections.details);
export const selectDTGridSectionIsShowAll = createSelector(
  selectDTCrewIsShowAll,
  selectDTDailyTimesIsShowAll,
  selectDTCodingIsShowAll,
  selectDTRerateIsShowAll,
  selectDTDetailsIsShowAll,
  (isCrewShowAll, isDailyTimesShowAll, isCodingShowAll, isRerateShowAll, isDetailsShowAll) =>
    ({isCrewShowAll, isDailyTimesShowAll, isCodingShowAll, isRerateShowAll, isDetailsShowAll})
);
export const selectIsShowLoadingMask = createSelector(
  _selectUiRootState,
  (state: UiState) => state && state.isShowLoadingMask
);

export const selectTcSummarySectionToFocusConfigs = createSelector(
  selectSummaryAccountCodingGroupConfig,
  selectSummaryJobDetailGroupConfig,
  selectSummaryPayDetailsCodingGroupConfig,
  selectSummaryBasicsGroupConfig,
  (summaryAccountCodeConfig, summaryJobConfig, summaryPayDetailConfig, summaryBasicsConfig) => ([
     summaryAccountCodeConfig,
     summaryJobConfig,
     summaryPayDetailConfig,
     summaryBasicsConfig
   ])
);

export const selectDtsManageColumnForCoding = createSelector(
  selectDtAccountCodingGroupConfig,
  _selectDtsManageColumnForCoding
);

export const selectSagDtsManageColumnForCoding = createSelector(
    selectSagDtAccountCodingGroupConfig,
    _selectDtsManageColumnForCoding
);

export const selectUiModal = createSelector(
  _selectUiRootState,
  _selectUiModal
);

export const selectLoadCrewSlice = createSelector(
  _selectUiRootState,
  (state: UiState): LoadCrew => state && state.loadCrew
);
export const selectLoadStartcardsSlice = createSelector(
  _selectUiRootState,
  (state: UiState): LoadStartcards => state && state.loadStartcards
);
export const selectDepartmentList = createSelector(
  selectLoadCrewSlice,
  (state: LoadCrew): Department[] => state && state.departmentList
);
export const selectSagDepartmentList = createSelector(
  selectLoadStartcardsSlice,
  (state: LoadStartcards): Department[] => state && state.departmentList
);
export const selectSelectedDepartmentId = createSelector(
  selectDepartmentList,
  (departments: Department[]): string => departments && _.find(departments, {'isSelected': true})?.id || ''
);
export const selectSelectedSagDepartmentId = createSelector(
  selectSagDepartmentList,
  (departments: Department[]): string => departments && _.find(departments, {'isSelected': true})?.id || ''
);
export const selectDepartmentStartcards = createSelector(
  selectLoadCrewSlice,
  (state: LoadCrew): DepartmentStartcards => state && state.departmentStartcards
);
export const selectSagDepartmentStartcards = createSelector(
  selectLoadStartcardsSlice,
  (state: LoadStartcards): DepartmentStartcards => state && state.sagDepartmentStartcards
);
export const selectStartcardsSelected = createSelector(
  selectDepartmentStartcards,
  (state: DepartmentStartcards) => {
    return _
      .chain(state)
      .map(department => department)
      .flattenDeep()
      .map(startcard => startcard)
      .filter(startcard => startcard.isSelected)
      .map(startcard => startcard.id)
      .value();
  }
);
export const selectSagStartcardsSelected = createSelector(
  selectSagDepartmentStartcards,
  (state: DepartmentStartcards) => {
    return _
      .chain(state)
      .map(department => department)
      .flattenDeep()
      .map(startcard => startcard)
      .filter(startcard => startcard.isSelected)
      .map(startcard => startcard.id)
      .value();
  }
);

export const selectSagPerformerIdsHavingSplit = (hasSplitCoding: boolean) => createSelector(
    selectDailyTimeEntries,
    (dailyTimes: DailyTimeNew[]): string[] => _
        .chain(dailyTimes)
        .map(dailyTime => dailyTime)
        .filter(dailyTime => dailyTime.isSelected && IsSplitCoding(dailyTime.accountCoding) === hasSplitCoding)
        .map(dailyTime => dailyTime.startcard.startcardId)
        .value()
);
export const selectIsCrewGroupSelected = createSelector(
  selectDepartmentStartcards,
  selectSelectedDepartmentId,
  (state: DepartmentStartcards, id) =>   state[id] ? _.every(state[id], 'isSelected') : false
);
export const selectIsStartcardGroupSelected = createSelector(
  selectSagDepartmentStartcards,
  selectSelectedSagDepartmentId,
  (state: DepartmentStartcards, id) =>   state[id] ? _.every(state[id], 'isSelected') : false
);
export const selectCrewSelectedCount = createSelector(
  selectDepartmentStartcards,
  (departmentStartcards: DepartmentStartcards) => _.reduce(departmentStartcards, (departmentStartcard, startcards, departmentId) => {
    departmentStartcard[departmentId] = _.chain(startcards)
      .filter('isSelected')
      .size()
      .value();
    return departmentStartcard;
  }, {})
);
export const selectStartcardSelectedCount = createSelector(
  selectSagDepartmentStartcards,
  (departmentStartcards: DepartmentStartcards) => _.reduce(departmentStartcards, (departmentStartcard, startcards, departmentId) => {
    departmentStartcard[departmentId] = _.chain(startcards)
      .filter('isSelected')
      .size()
      .value();
    return departmentStartcard;
  }, {})
);

export const selectLastVisited = createSelector(
  _selectUiRootState,
  ({lastVisitedPageUrl, lastVisitedProjectId}) => ({lastVisitedPageUrl, lastVisitedProjectId})
);

export const selectAccountCodingSegmentsDetails = createSelector(
  _selectUiRootState,
  (uiState: UiState) => !_.isEmpty(uiState) ? uiState.accountCoding?.segments : null
);

export const selectAccountCodingColumnSegmentsDetails = createSelector(
  selectAccountCodingSegmentsDetails,
  (segments: AccountCodeSegmentConfig[]) => segments && _selectAccountCodingColConfigs(segments)
);

// Day cost report screen manage column based selectors
export const selectDayCostReportGridConfig = createSelector(
  selectColumnConfigs,
  (state: fromManageColumn.ColumnConfigStore) => state && state?.CREW?.dayCostReportGrid
);

const _getDayCostGridIsHiddenStatus = (section: fromManageColumn.Sections) => createSelector(
  selectDayCostReportGridConfig,
  dcrGridConfig => _selectSectionIsHidden(dcrGridConfig, section)
);
const _getDayCostCrewIsHidden = _getDayCostGridIsHiddenStatus(fromManageColumn.Sections.crew);
const _getDayCostAccountCodingIsHidden = _getDayCostGridIsHiddenStatus(fromManageColumn.Sections.accountCoding);
const _getDayCostBudgetIsHidden = _getDayCostGridIsHiddenStatus(fromManageColumn.Sections.budgetedAmounts);
const _getDayCostGridGroupConfig = (section: fromManageColumn.Sections) => createSelector(
  selectDayCostReportGridConfig,
  dayCostGridConfig => _selectColumnGroupConfig(dayCostGridConfig, section)
);
const _selectDayCostCrewGroupConfig  = _getDayCostGridGroupConfig(fromManageColumn.Sections.crew);
const _selectDayCostAccountCodingGroupConfig  = _getDayCostGridGroupConfig(fromManageColumn.Sections.accountCoding);
const _selectDayCostBudgetedAmountsGroupConfig  = _getDayCostGridGroupConfig(fromManageColumn.Sections.budgetedAmounts);
const _getDayCostGridVisibilityData = (section: fromManageColumn.Sections) => createSelector(
  selectDayCostReportGridConfig,
  dayCostGridConfig => _selectSectionIsVisible(dayCostGridConfig, section)
);
const _getDayCostCrewVisibility = _getDayCostGridVisibilityData(fromManageColumn.Sections.crew);
const _getDayCostAccountCodingVisibility = _getDayCostGridVisibilityData(fromManageColumn.Sections.accountCoding);
const _getDayCostBudgetVisibility = _getDayCostGridVisibilityData(fromManageColumn.Sections.budgetedAmounts);

export const selectDayCostSectionsHiddenStatus = createSelector(
  _getDayCostCrewIsHidden,
  _getDayCostAccountCodingIsHidden,
  _getDayCostBudgetIsHidden,
  (crew, accountCoding, budgetedAmounts) => ({crew, accountCoding, budgetedAmounts})
);

export const selectDayCostSectionColumnsVisibility = (section: fromManageColumn.Sections) => createSelector(
  selectDayCostReportGridConfig,
  selectDayCostSectionsHiddenStatus,
  (columnConfig, sectionsHiddenStatus) => _selectSectionColumnsVisibility(columnConfig, sectionsHiddenStatus, section)
);

export const selectDayCostGridGroupConfig = createSelector(
  _selectDayCostCrewGroupConfig,
  _selectDayCostAccountCodingGroupConfig,
  _selectDayCostBudgetedAmountsGroupConfig,
  (crewConfig, accountCodingConfig, budgetedAmounts) =>
    [crewConfig, accountCodingConfig, budgetedAmounts]
);

// gets number of selected column for manage column
export const selectDayCostGridVisibleColumnCount = createSelector(
  selectDayCostReportGridConfig,
  _selectVisibleColumnCount
);

// selectors to handle section wise visibility for day cost report manage column
export const selectDayCostGridAllSectionsVisibility = createSelector(
  _getDayCostCrewVisibility,
  _getDayCostAccountCodingVisibility,
  _getDayCostBudgetVisibility,
  (isCrewVisible, isAccountCodingVisible, isBudgetVisible) =>
    ({
      [fromManageColumn.Sections.crew]: isCrewVisible,
      [fromManageColumn.Sections.accountCoding]: isAccountCodingVisible,
      [fromManageColumn.Sections.budgetedAmounts]: isBudgetVisible
    })
);
