import * as _ from 'lodash';
import {combineLatest, Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {catchError, switchMap, take} from 'rxjs/operators';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {AuthService} from './auth.service';
import {ProjectPermissions, ProjectSettings, SelectedProject} from '../actions/auth';
import {ProjectDetailsDataService} from '../../shared/data-services/project-details.data.service';
import {Permission, PermissionMap, PermissionType} from '../../../../common/user-profile.model';
import {State} from '../reducers';
import {selectIsLoggedIn, selectIsUserEnrollmentPending} from '../selectors';
import {getState} from '../../shared/utils';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _router: Router,
              private store: Store<State>,
              private _authService: AuthService,
              private _projectDetailsService: ProjectDetailsDataService) {}

  getFromStoreOrAPI(): Observable<any> {
    return this.store.pipe(
      select(selectIsLoggedIn),
      take(1)
    );
  }

  _isRouteEnabled(permissionsToCheck: PermissionType[], permissionSet: PermissionMap) {
    if (_.isEmpty(permissionSet)) {
      return false;
    }
    return _.some(permissionsToCheck, (permissionType: PermissionType) => permissionSet[permissionType] !== Permission.NONE);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const nextProjectId = next.params['projectId'];
    const { permissionsToCheck } = next?.data;
    return combineLatest(
      this._authService.retrieveLiteUserProfile(nextProjectId),
      this.getFromStoreOrAPI(),
      this._projectDetailsService.RetrieveProjectSettings(nextProjectId, projectSettings => projectSettings))
      .pipe(
        switchMap(([{projectPermissions, projectDetails}, isLoggedIn, projectSettings]) => {
          // dispatch action to persist user permission to Auth store
          this.store.dispatch(ProjectPermissions(projectPermissions));
          this.store.dispatch(SelectedProject(projectDetails));
          this.store.dispatch(ProjectSettings(projectSettings));
          const userPermissionSet: PermissionMap = projectPermissions?.permissions;
          const isUserEnrollmentPending = getState<boolean>(this.store, selectIsUserEnrollmentPending);

          if (isUserEnrollmentPending) {
            this._router.navigate([`/projects/${nextProjectId}/project-eula`]);
            return of(false);
          }
          if (!_.isEmpty(permissionsToCheck) && !this._isRouteEnabled(permissionsToCheck, userPermissionSet)) {
            return of(false);
          }
          return of(isLoggedIn);
        }),
        catchError(() => {
          console.log(`Redirect to Login`);
          return of(false);
        })
      );
  }
}
