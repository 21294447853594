import { Injectable } from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import { Store } from '@ngrx/store';
import {catchError, tap} from 'rxjs/operators';

import {
  RetrieveAllPayCodesFailed, RetrieveAllPayCodesSuccessful} from '../actions/lookup.action';
import {PayCode, PayType2Map} from '../../../common/pay-code.model';
import {LookupDataService} from './lookup.data.service';

@Injectable()
export class PayCodeResolver implements Resolve<PayType2Map> {
  constructor( private _lookupService: LookupDataService, private _store: Store<any>) {}

  resolve(): Observable<PayType2Map>|Promise<any>|any {
    return this._lookupService.RetrieveAllPayCodes().pipe(
      catchError(() => {
        this._store.dispatch(RetrieveAllPayCodesFailed());
        return of();
      }),
      tap((payCodes: PayCode[]) => this._store.dispatch(RetrieveAllPayCodesSuccessful(payCodes)))
    );
  }
}
