import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Store} from '@ngrx/store';
import {catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import {Paymaster} from '../../../common/paymaster.model';
import {RetrievePaymasterSuccessful} from '../actions/lookup.action';
import {ProjectDetailsDataService} from '../shared/data-services/project-details.data.service';

@Injectable({providedIn: 'root'})
export class PaymasterResolver implements Resolve<Paymaster[]> {
  constructor(private _store: Store<any>, private _projectDataService: ProjectDetailsDataService) {}

  resolve(): any {
    return this._projectDataService.RetrievePaymasters().pipe(
      catchError(() => {
        return of();
      }),
      tap((paymasters: Paymaster[]) => this._store.dispatch(RetrievePaymasterSuccessful(paymasters)))
    );
  }
}
