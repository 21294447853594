import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as _ from 'lodash';

import {DailyTimeNew, DtAccountCoding, IsSplitCoding} from '../../../../../common/daily-time.model';
import {TimecardLookupData, ValueError} from '../../../../../common/timecard.model';
import {SagDailyTimeUIState} from '../../reducers/sag/daily-time-ui.reducer';
import {SagDailyTimeFeatureState} from '../../reducers/sag';
import {selectAccountCodeConfigSegments} from '../../../auth/selectors';
import {AccountCodeSegmentConfig} from '../../../../../common/project-setting.model';
import {DefaultBulkInputStartCardId} from '../../../shared/constants';
import {selectBulkInputAccountCodingNew} from './bulk-input-line.selector';
import {StartcardAccountCodeSegment} from '../../../../../common/start-card.model';

const selectDailyTimeFeatureState = createFeatureSelector<SagDailyTimeFeatureState>('dailyTimeSag');
const getDailyTimeSlice = (state: SagDailyTimeFeatureState): DailyTimeNew[] => state?.dailyTimeEntries || [];
const getUiSlice = (state: SagDailyTimeFeatureState): any => state?.ui || {};
const getTimecardLookupData = (state: SagDailyTimeUIState): TimecardLookupData => state?.timecardLookupData;

export const selectDailyTimeEntries = createSelector(
  selectDailyTimeFeatureState,
  getDailyTimeSlice
);
export const selectLoadedStartcardIds = createSelector(
  selectDailyTimeEntries,
  (dailyTimes: DailyTimeNew[]): string[] => _.map(dailyTimes, dailyTime => dailyTime.startcard.startcardId)
);
export const selectSelectedStartcardIds = createSelector(
  selectDailyTimeEntries,
  (dailyTimes: DailyTimeNew[]) =>  _
    .chain(dailyTimes)
    .filter(dailyTime => dailyTime.isSelected)
    .map(dailyTime => dailyTime.startcard.startcardId)
    .value()
);

export const selectDailyTimeRow = (startcardId: string) => createSelector(
  selectDailyTimeEntries,
  (dailyTimes: DailyTimeNew[]): DailyTimeNew => dailyTimes && _.find(dailyTimes, (dt: DailyTimeNew) => dt.startcard.startcardId === startcardId)
);

export const selectDailyTimeIndexNew = (startcardId: string) => createSelector(
  selectDailyTimeEntries,
  (state: DailyTimeNew[]) => state && _.findIndex(state, (dt: DailyTimeNew) => dt.startcard.startcardId === startcardId)
);

export const selectDtAllowancesNew = (startcardId: string) => createSelector(
  selectDailyTimeRow(startcardId),
  (dailyTime: DailyTimeNew) => {
    return _.isEmpty(dailyTime)
      ? []
      : dailyTime.allowances;
  }
);

export const selectAccountCodingHeadersNew = (startcardId: string) => createSelector(
  selectAccountCodeConfigSegments,
  selectDailyTimeEntries,
  (accountCodes: AccountCodeSegmentConfig[], dailyTimes: DailyTimeNew[]): AccountCodeSegmentConfig[] | StartcardAccountCodeSegment[] => {
    // returning account codes config from project settings if it is a bulk input line
    if (startcardId === DefaultBulkInputStartCardId) {
      return accountCodes;
    }
    const dailyTime = dailyTimes && _.find(dailyTimes, (dt: DailyTimeNew) => dt.startcard.startcardId === startcardId);
    return _.isEmpty(dailyTime)
      ? []
      : dailyTime.startcard.accountCodeSegments;
  }
);

export const selectAllowanceDefaultAccountCodesNew = (startcardId: string) => createSelector(
  selectDailyTimeRow(startcardId),
  selectAccountCodeConfigSegments,
  (dailyTime: DailyTimeNew, projectAccountCodeConfig: AccountCodeSegmentConfig[]) => {
    return startcardId === DefaultBulkInputStartCardId
      ? projectAccountCodeConfig.reduce((acc, config) => ({...acc, [config.header]: {value: config.defaultValue}}), {})
      : dailyTime.startcard.accountCodeSegments.reduce((acc, config) => ({...acc, [config.header]: {value: config.value}}), {});
  }
);

export const selectDailyTimeEntriesCountNew = createSelector(
  selectDailyTimeEntries,
  (dailyTimes: DailyTimeNew[]): number => dailyTimes ? dailyTimes?.length : 0
);

export const selectIsAllSagDailyTimeSelected = createSelector(
    selectDailyTimeEntries,
    (state: DailyTimeNew[]) =>   state && state.length > 0  ? _.every(state, ['isSelected', true]) : false
);

export const selectSplitDefaultAccountCodesNew = (startcardId: string) => createSelector(
  selectDailyTimeEntries,
  selectBulkInputAccountCodingNew,
  selectAccountCodeConfigSegments,
  (dailyTimes: DailyTimeNew[], bulkInputAccountCoding, projectAccountCodeConfig: AccountCodeSegmentConfig[]) => {
    if (startcardId === DefaultBulkInputStartCardId) {
      return !_.isEmpty(bulkInputAccountCoding) && !IsSplitCoding(bulkInputAccountCoding)
        ? bulkInputAccountCoding
        : {};
    }
    const selectedDailyTime = _.find(dailyTimes, (dt: DailyTimeNew) => dt.startcard.startcardId === startcardId);
    if (_.isEmpty(selectedDailyTime)) {
      return {};
    }
    return !_.isEmpty(selectedDailyTime.accountCoding) && !IsSplitCoding(selectedDailyTime.accountCoding)
      ? selectedDailyTime.accountCoding
      : selectedDailyTime.startcard.accountCodeSegments.reduce((acc, config) => ({...acc, [config.header]: {value: config.value}}), {});
  }
);

export const selectRerateJobCodeValue = (startcardId: string) => createSelector(
  selectDailyTimeRow(startcardId),
  (dailyTime: DailyTimeNew): string => _.get(dailyTime, 'rerateJobCode.value', '')
);

export const selectWorkStatus = (startcardId: string) => createSelector(
  selectDailyTimeRow(startcardId),
  (dailyTime: DailyTimeNew): ValueError<ValueError<string>[]> => !_.isEmpty(dailyTime?.workStatuses) ? dailyTime.workStatuses : {value : []}
);

export const selectDailyTimeRowAccountCodes = (startcardId: string, valuePath: string) => createSelector(
  selectDailyTimeRow(startcardId),
  (dailyTime: DailyTimeNew): ValueError<string> => _.get(dailyTime, `accountCoding.${valuePath}`, {value: ''})
);

export const selectDailyTimeRowPunches = (startcardId: string, valuePath: string) => createSelector(
  selectDailyTimeRow(startcardId),
  (dailyTime: DailyTimeNew): ValueError<string> => _.get(dailyTime, `punches.${valuePath}`, {value: ''})
);

export const selectDailyTimeRowDetails = (startcardId: string, valuePath: string) => createSelector(
  selectDailyTimeRow(startcardId),
  (dailyTime: DailyTimeNew): string|boolean => _.get(dailyTime, `workDetails.${valuePath}`, '')
);

export const selectDailyTimeRowDetailsErrorValue = (startcardId: string, valuePath: string) => createSelector(
  selectDailyTimeRow(startcardId),
  (dailyTime: DailyTimeNew): ValueError<string> => _.get(dailyTime, `workDetails.${valuePath}`, {value: ''})
);

export const selectDailyTimeRowDetailsCheckboxChecked = (startcardId: string, valuePath: string) => createSelector(
  selectDailyTimeRow(startcardId),
  (dailyTime: DailyTimeNew): boolean => _.get(dailyTime, `workDetails.${valuePath}`, false)
);

export const selectDailyTimeRowAccountCoding = (startcardId: string) => createSelector(
  selectDailyTimeRow(startcardId),
  (dailyTime: DailyTimeNew): DtAccountCoding => dailyTime?.accountCoding
);

export const selectDailyTimeRowGlShortcutId = (startcardId: string) => createSelector(
  selectDailyTimeRow(startcardId),
  (dailyTime: DailyTimeNew): string => dailyTime?.glShortcutId ?? ''
);

export const selectDailyTimeRowPunchValue = (startcardId: string, valuePath: string) => createSelector(
    selectDailyTimeRow(startcardId),
    (dailyTime: DailyTimeNew): number => dailyTime?.punches ? dailyTime?.punches[valuePath]?.value || 0 : ''
);

export const selectDailyTimeRowStuntAdjustment = (startcardId: string) => createSelector(
  selectDailyTimeRow(startcardId),
  (dailyTime: DailyTimeNew): ValueError<number> => !_.isEmpty(dailyTime?.stuntAdjustment) ? dailyTime.stuntAdjustment : null
);
