import {Event, NavigationEnd, NavigationStart, Router, Routes} from '@angular/router';

import {AuthGuard} from './auth/services/auth-guard.service';
import {ProjectComponent} from './core/containers/project.component';
import {ProjectBrowseComponent} from './core/containers/project-browse/project-browse.component';
import {PermissionType} from '../../common/user-profile.model';
import {PaymasterLookupResolver} from './paymaster/services/paymaster-lookup.resolver';
import {ProjectEulaCanActivateGuard} from './project-eula/services/project-eula.can.activate.guard.service';
import {PayCodeResolver} from './services/pay-code.resolver';
import {DepartmentResolver} from './services/department.resolver';
import {UnionResolver} from './services/union.resolver';
import {GlShortcutsResolver} from './services/gl-shortcuts.resolver';
import {TemplateResolver} from './services/template.resolver';
import {TaxJurisdictionLookupResolver} from './services/tax-jurisdiction-lookup.resolver';
import {ProjectBrowseResolver} from './services/project-browse-resolver.service';
import {PaymanagerResolver} from './services/paymanager.resolver';
import { PaymasterResolver } from './services/paymaster.resolver';
import {WorkStatusResolver} from './services/work-status.resolver';
import {TimecardLookupDataResolver} from './services/timecard-lookup-data.resolver';
import {StartcardExportReportOptionResolver} from './services/startcard-export-report-option.resolver';
import {PayrollDistributionExportReportOptionResolver} from './services/payroll-distribution-export-report-option.resolver';
import {UserDepartmentsResolver} from './services/user-departments.resolver';

export const routes: Routes = [
  {
    path: '',
    title: 'SmartTime',
    redirectTo: 'projects/list',
    pathMatch: 'full'
  },
  /*// lazy load User Settings and Reports modules
    { path: 'user-settings', loadChildren: './user-settings/user-settings.module#UserSettingsModule', canActivate: [AuthGuard]},
    { path: 'reports', loadChildren: './reports/report.module#ReportModule', canActivate: [AuthGuard]},
  */
  {
    path: 'paymasters',
    title: 'SmartTime',
    loadChildren: () => import('./paymaster/paymaster.module').then(m => m.PaymasterModule),
    resolve: {
      lookupData: PaymasterLookupResolver,
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'projects',
    title: 'SmartTime',
    redirectTo: 'projects/list',
    pathMatch: 'full'
  },
  {
    path: 'projects/list',
    title: 'SmartTime',
    component: ProjectBrowseComponent,
    resolve: {
      projectBrowse: ProjectBrowseResolver,
      paymasterResolver: PaymasterResolver,
      paymanagerResolver: PaymanagerResolver,
    }
  },
  {
    path: 'projects/:projectId',
    title: 'SmartTime',
    component: ProjectComponent,
    resolve: {
      payCode: PayCodeResolver,
      department: DepartmentResolver,
      union: UnionResolver,
      glShortcuts: GlShortcutsResolver,
      template: TemplateResolver,
      taxJurisdiction: TaxJurisdictionLookupResolver,
      workStatusCombination: WorkStatusResolver,
      lookupData: TimecardLookupDataResolver,
      exportReportOption: StartcardExportReportOptionResolver,
      PayrollExportReportOption: PayrollDistributionExportReportOptionResolver,
      userDepartments: UserDepartmentsResolver
    },
    children: [
      {
        path: 'project-eula',
        title: 'SmartTime',
        loadChildren: () => import('./project-eula/project-eula.module').then(m => m.ProjectEulaModule),
        canActivate: [ProjectEulaCanActivateGuard]
      },
      {
        path: 'timecards',
        title: 'SmartTime',
        loadChildren: () => import('./timecard/timecard.module').then(m => m.TimecardModule),
        canActivate: [AuthGuard],
        data: {permissionsToCheck: [PermissionType.TIMECARD]}
      },
      {
        path: 'batches',
        title: 'SmartTime',
        loadChildren: () => import('./batch/batch.module').then(m => m.BatchModule),
        data: {preload: true, permissionsToCheck: [PermissionType.BATCH_MANAGEMENT]},
        canActivate: [AuthGuard],
      },
      {
        path: 'startcards',
        title: 'SmartTime',
        loadChildren: () => import('./startcard/startcard.module').then(m => m.StartcardModule),
        canActivate: [AuthGuard],
        data: {permissionsToCheck: [PermissionType.START_CARD]}
      },
      {
        path: 'templates',
        title: 'SmartTime',
        loadChildren: () => import('./template/template.module').then(m => m.TemplateModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'settings',
        title: 'SmartTime',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'user-management',
        title: 'SmartTime',
        loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule),
        canActivate: [AuthGuard],
        data: {permissionsToCheck: [PermissionType.USER_MANAGEMENT]}
      },
      {
        path: 'approvals',
        title: 'SmartTime',
        loadChildren: () => import('./approvals/approvals.module').then(m => m.ApprovalsModule),
        canActivate: [AuthGuard],
        data: {permissionsToCheck: [PermissionType.TIMECARD_APPROVALS]}
      },
      {
        path: 'daily-times',
        title: 'SmartTime',
        loadChildren: () => import('./daily-time/daily-time.module').then(m => m.DailyTimeModule),
        canActivate: [AuthGuard],
        data: {permissionsToCheck: [PermissionType.DAILY_TIME_SHEET]}
      },
      {
        path: 'help',
        title: 'SmartTime',
        loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reports',
        title: 'SmartTime',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuard]
      }
    ]
  },
];
declare var ADRUM: any;
export class AppRoutingModule {
  vpView: any;

  // Subscribe to the Router URL changes and report the metrics to AppDynamics.
  constructor(public router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.vpView.markViewChangeEnd();
        this.vpView.markViewDOMLoaded();
        this.vpView.markXhrRequestsCompleted();
        this.vpView.markViewResourcesLoaded();
        this.vpView.end();
        ADRUM.report(this.vpView);

      } else if (event instanceof NavigationStart) {

        this.vpView = new ADRUM.events.VPageView();
        this.vpView.start();
        this.vpView.markViewChangeStart();
      }
    });
  }
}
