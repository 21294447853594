import { Injectable } from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import { Store } from '@ngrx/store';
import {tap} from 'rxjs/operators';

import {RetrieveDepartmentsSuccessful} from '../actions/lookup.action';
import {ProjectDetailsDataService} from '../shared/data-services/project-details.data.service';
import {getState} from '../shared/utils';
import {selectSelectedProjectId} from '../auth/selectors';
import {Department, DepartmentSetting} from '../../../common/department-setting.model';

@Injectable()
export class DepartmentResolver implements Resolve<Department[]> {
  constructor( private  _projectSettingService: ProjectDetailsDataService, private _store: Store<any>) {}

  resolve(): Observable<Department[]>|Promise<any>|any {
    const projectId = getState<string>(this._store, selectSelectedProjectId);
    return this._projectSettingService.RetrieveDepartmentDetails(projectId).pipe(
      tap(({departments}: DepartmentSetting) => this._store.dispatch(RetrieveDepartmentsSuccessful(departments)) )
    );
  }
}
