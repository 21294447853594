import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate} from '@angular/router';
import { Observable } from 'rxjs';
import {Store} from '@ngrx/store';

import {getState} from '../../shared/utils';
import {State} from '../../auth/reducers';
import {selectIsUserEnrollmentPending} from '../../auth/selectors';
import {ApiRoutes, ServerRoutes} from '../../../../common/api-routes';

@Injectable({
  providedIn: 'root'
})
export class ProjectEulaCanActivateGuard implements CanActivate {

  constructor(private _store: Store<State>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    const isUserEnrollmentPending = getState<boolean>(this._store, selectIsUserEnrollmentPending);
    if (!isUserEnrollmentPending) {
      window.location.href = ServerRoutes(ApiRoutes.Login);
      return false;
    }
    return isUserEnrollmentPending;
  }
}
