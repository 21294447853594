import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';

import {TimecardLookupData, TimecardTypes} from '../../../common/timecard.model';
import {TimecardLookupService} from '../shared/providers/timecard-lookup.service';
import {RetrieveTimecardLookupSuccessful} from '../actions/lookup.action';

@Injectable()
export class TimecardLookupDataResolver implements Resolve<{ [key in keyof typeof TimecardTypes]?: TimecardLookupData }> {
  constructor(private _timecardLookupService: TimecardLookupService, private _store: Store) {}

  resolve(): Observable<{ [key in keyof typeof TimecardTypes]?: TimecardLookupData }> {
    return forkJoin([
      this._timecardLookupService.retrieveTimecardLookupData(),
      this._timecardLookupService.retrieveSagTimecardLookupData()
    ]).pipe(
      map(([crewLookup, sagLookup]): { [key in keyof typeof TimecardTypes]?: TimecardLookupData } => ({
          CREW: crewLookup,
          SAG: sagLookup
      })),
      tap((timecardLookup: { [key in keyof typeof TimecardTypes]?: TimecardLookupData }) => {
        this._store.dispatch(RetrieveTimecardLookupSuccessful(timecardLookup));
      })
    );
  }
}
