import {ApiInvoiceStatus, PayrollBatch} from './paymaster-batch.model';
import {ApprovalTask, MobileApprovalLoop} from './timecard.model';
import {DocumentMetadata} from './document.model';

export interface Batch {
  id: string;
  name: string;
  date: string;
}

export interface LiteBatch {
  id: string;
  name: string;
}

export interface BatchSummary {
  name: string;
  id: string;
  projectId: string;
  payPeriodEnding: string;
  timecards: number;
  issues: string;
  hasUnsubmittableErrors: boolean;
  hasSubmittableErrors: boolean;
  documentMetadata: DocumentMetadata[];
  approved: string;
  invoice: string;
  status: BatchStatus;
  received: string;
  deliveryMethod: string;
  paymaster: string;
  urlParams?: string[];
  isInternal: boolean;
  createdBy: string;
  payrollBatchDetails: PayrollBatch[];
}

export interface BatchSummaryResponse {
  total?: number;
  batchSummaries: BatchSummary[];
}

export enum BatchStatus {
  SUBMITTED = 'SUBMITTED',
  UNSUBMITTED = 'UNSUBMITTED',
  PROCESSED = 'PROCESSED'
}

export enum BatchError {
  CORRECTABLE= 'CORRECTABLE',
  UNCORRECTABLE= 'UNCORRECTABLE',
  NOERRORS= 'NO ERRORS',
}

export interface BatchTimecardSummary {
  timecardId: string;
  crewName: string;
  ssn: string;
  fein: string;
  weekEnding: string;
  jobTitle: string;
  department: string;
  accountCode: string;
  days: number;
  amount: number;
  grossAmount: number;
  issues: string;
  isInternal: boolean;
  hasUnsubmittableErrors: boolean;
  hasSubmittableErrors: boolean;
  keyedBy: string;
  timecardInvoiceStatus: ApiInvoiceStatus;
  queryParams: { batchId: string , weekEndingDate: string };
  batchTimecardSummaryUrlParams?: string[];
  approvalTasks: ApprovalTask[];
  isDisplayOrangeCircle: boolean;
  isApplyOtherDataSources: boolean;
  isMobileApproved: boolean;
  isMobileTimecard: boolean;
  isDailyTimecard: boolean;
  mobileApprovalLoops?: MobileApprovalLoop[];
}

export interface BatchTimecardSummaryResponse {
  total?: number;
  batchTimecardSummary: BatchTimecardSummary[];
}

export enum BatchDuplicateStatus {
  Completed = 'Completed',
  Processing = 'Processing',
  Failed = 'Failed',
  NotStarted = 'Not Started',
  AlreadyExists = 'Already Exists'
}

export interface DuplicateBatch {
  id: string;
  name: string;
  payPeriodEnding: string;
  retainTimecard: boolean;
  retainTimecardNotes: boolean;
  status: BatchDuplicateStatus;
}

export interface ErrorTimecard {
  id: string;
  crewName: string;
  ssn: string;
  job: string;
  weekEnding: string;
}

export interface ErrorTimecardResponse {
  total?: number;
  errorTimecards: ErrorTimecard[];
}

export interface BatchNote {
  batchId: string;
  message: string;
  createdBy: string;
  createdTimestamp: string;
}
