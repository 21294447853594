import { Injectable } from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import { Store } from '@ngrx/store';
import {tap} from 'rxjs/operators';

import {RetrieveTimecardTemplateSuccessful} from '../actions/lookup.action';
import {getState} from '../shared/utils';
import {selectSelectedProjectId} from '../auth/selectors';
import { TimecardTemplateService } from '../template/timecards/services/timecard-template.service';
import {TimecardTemplate} from '../../../common/template.model';

@Injectable()
export class TemplateResolver implements Resolve<TimecardTemplate> {
  constructor(private _timecardTemplateService: TimecardTemplateService, private _store: Store<any>) {}

  resolve(): Observable<TimecardTemplate>|Promise<any>|any {
    const projectId = getState<string>(this._store, selectSelectedProjectId);
    return this._timecardTemplateService.retrieveTemplate(projectId).pipe(
      tap((timecardTemplate: TimecardTemplate) => this._store.dispatch(RetrieveTimecardTemplateSuccessful(timecardTemplate)))
    );
  }
}
