import { Injectable } from '@angular/core';
import {Resolve} from '@angular/router';
import {Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import * as _ from 'lodash';

import {EmptyProjects, GridColumnConfig, RetrieveProjects} from '../core/actions/project-browse.action';
import {TimecardTemplate} from '../../../common/template.model';
import {ProjectBrowseConstants} from '../../../constants/project-browse.constants';
import ProjectBrowse = ProjectBrowseConstants.ProjectBrowse;

@Injectable({providedIn: 'root'})
export class ProjectBrowseResolver implements Resolve<any> {
  constructor(private  _store: Store) { }
  resolve():  Observable<TimecardTemplate>|Promise<any>|any {
    const projectBrowseColConfigs = ProjectBrowse.InternalMasterColumnList;
    this._store.dispatch(GridColumnConfig({
      all: {
        isShowAllColumns: false,
        groupName: 'Show ALL',
        section: 'all',
        columns: _.keyBy(projectBrowseColConfigs, 'field')
      }
    }));
    this._store.dispatch(EmptyProjects());
    this._store.dispatch(RetrieveProjects());
    return of(false);
  }
}
