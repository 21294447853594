import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask-2';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IMaskModule} from 'angular-imask';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';

import { AppComponent } from './containers/app.component';
import { ProjectPickerComponent } from './components/project-picker.component';
import { ProjectComponent } from './containers/project.component';
import { ProjectBrowseComponent } from './containers/project-browse/project-browse.component';
import { SharedModule } from '../shared/shared.module';
import { UserMenuComponent } from './components/user-menu.component';
import { ProjectBrowseGridComponent } from './components/project-browse-grid/project-browse-grid.component';
import { ProjectSearchComponent } from './components/project-search.component';
import { MaterialModule } from '../material/material.module';
import {CreateProjectModalComponent} from './components/create-project-modal/create-project-modal.component';
import {AdvancedReportsComponent} from './components/advanced-reports/advanced-reports.component';
import {metaReducers, ProjectBrowseReducerProvider, ProjectBrowseReducerToken} from './reducers';
import {ProjectBrowseEffect} from './effects/project-browse.effect';

export const COMPONENTS = [
  AppComponent,
  ProjectComponent,
  ProjectPickerComponent,
  ProjectBrowseComponent,
  UserMenuComponent,
  ProjectBrowseGridComponent,
  ProjectSearchComponent,
  CreateProjectModalComponent,
  AdvancedReportsComponent
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        NgbModule,
        SharedModule,
        NgxMaskModule,
        ScrollingModule,
        MaterialModule,
        ReactiveFormsModule,
        IMaskModule,
        StoreModule.forFeature('project', ProjectBrowseReducerToken, {metaReducers}),
        EffectsModule.forFeature([
          ProjectBrowseEffect
        ])
    ],
  providers: [
    {provide: Window, useValue: window},
    ProjectBrowseReducerProvider
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
        ngModule: CoreModule,
        providers: [],
    };
}
}
