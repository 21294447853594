import { Injectable } from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import { Store } from '@ngrx/store';
import {tap} from 'rxjs/operators';

import {RetrieveGlShortcutsSuccessful} from '../actions/lookup.action';
import {ProjectDetailsDataService} from '../shared/data-services/project-details.data.service';
import {getState} from '../shared/utils';
import {selectSelectedProjectId} from '../auth/selectors';
import {GLShortcutsViewModel} from '../../../common/project-setting.model';
import {GlShortcutConfigViewModel} from '../settings/models/project-settings-view.model';

@Injectable()
export class GlShortcutsResolver implements Resolve<GLShortcutsViewModel[]> {
  constructor( private  _projectSettingService: ProjectDetailsDataService, private _store: Store<any>) {}

  resolve(): Observable<GLShortcutsViewModel[]>|Promise<any>|any {
    const projectId = getState<string>(this._store, selectSelectedProjectId);
    return this._projectSettingService.RetrieveGlShortcutDetails(projectId).pipe(
      tap(({glShortcuts}: GlShortcutConfigViewModel) => this._store.dispatch(RetrieveGlShortcutsSuccessful(glShortcuts)))
    );
  }
}
