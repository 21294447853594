import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {NgxEditorModule} from 'ngx-editor';
import {NgxMaskModule} from 'ngx-mask-2';
import {Store} from '@ngrx/store';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {MaterialModule} from '../material/material.module';
import {SortComponent} from './components/sort/sort.component';
import {FilterPanelComponent} from './components/filter-panel/filter-panel.component';
import {TextFilterComponent} from './components/text-filter/text-filter.component';
import {HandlePopoverCloseDirective} from './components/ngb-popover-close/ngb-popover-close.component';
import {GridColumnFilterComponent} from './components/grid-column-filter/grid-column-filter.component';
import {GridColumnLoaderComponent} from './components/grid-column-loader/grid-column-loader.component';
import {DefaultGridColumnComponent} from './components/grid-column-components/default-grid-column.component';
import {FavoriteStarColumnComponent} from './components/grid-column-components/favorite-star-column.component';
import {LinkButtonColumnComponent} from './components/grid-column-components/link-button-column.component';
import {StartCasePipe} from './pipes/start-case.pipe';
import {QuickActionMenuComponent} from './components/quick-action-menu/quick-action-menu.component';
import {TabOrderDirective} from './components/tab-order/tab-order.component';
import {AutoTabDirective} from './components/auto-tab/auto-tab.component';
import {ManageColumnComponent} from './components/manage-column/manage-column.component';
import {WeekEndingDatePickerComponent} from './components/week-ending-date-picker.component';
import {TimecardApprovalsCirclesComponent} from './components/timecard-approvals-circles.component';
import {CreateNewMaskComponent} from './components/create-new-mask/create-new-mask.component';
import {DisplayErrorDirective} from './components/validation/display-error.component';
import {NumericMaskDirective} from './components/numeric-mask/numeric-mask.directive';
import {JobSearchComponent} from '../timecard/components/job-search/job-search.component';
import {HighlightPipe} from './pipes/highlight.pipe';
import {ProjectDetailsDataService} from './data-services/project-details.data.service';
import {TimecardNotesDataService} from './data-services/timecard-notes.data.service';
import {PermissionToDisplayDirective} from './components/permission/permission-to-display.directive';
import {PermissionToEnableDirective} from './components/permission/permission-to-enable.directive';
import {DeclineTimecardNoteModalComponent} from './modals/decline-timecard-note-modal/decline-timecard-note-modal.component';
import {TimecardWaitingForApprovalModalComponent} from './modals/timecard-waiting-for-approval-modal.component';
import {TimecardChangedModalComponent} from './modals/timecard-changed-modal.component';
import {ChangeDepartmentIdentifierModalComponent} from './modals/change-department-identifier-modal.component';
import {ConfirmDeleteTimecardModalComponent} from './modals/confirm-delete-timecard-modal.component';
import {ConfirmDeleteBatchModalComponent} from './modals/confirm-delete-batch-modal.component';
import {DupNewUserModalComponent} from './modals/dup-new-user-modal/dup-new-user-modal.component';
import {ChangingApprovalWorkflowModalComponent} from './modals/changing-approval-workflow-modal.component';
import {ReviseTimecardNoteModalComponent} from './modals/revise-timecard-note-modal/revise-timecard-note-modal.component';
import {MaskNumberPipe} from './pipes/mask-number.pipe';
import {UnsavedChangesConfirmationModalComponent} from './modals/unsaved-changes-confirmation-modal.component';
import {ApprovalWorkflowConfigLevelChangeConfirmationComponent} from './modals/approval-workflow-config-level-change-confirmation.component';
import {PaymasterPickerModalComponent} from './modals/paymaster-picker-modal-component';
import {EllipsisPipe} from './pipes/ellipsis.pipe';
import {DisplaySplitCodeDirective} from './components/split-coding-input.directive';
import {SplitCodingDisplayDeleteDirective} from './components/split-coding-display-delete.directive';
import {SplitCodingScrollDirective} from './components/split-coding-scroll.directive';
import {CrewPickerComponent} from './components/crew-picker/crew-picker.component';
import {DuplicateTimecardModalComponent} from './modals/duplicate-timecard-modal.component';
import {WorkLocationPickerComponent} from './components/work-location-picker/work-location-picker.component';
import {ConfirmDeleteModalComponent} from './modals/confirm-delete-modal.component';
import {EasterEggPopupComponent} from './components/easter-egg-popup/easter-egg-popup.component';
import {ConfirmSaveModalComponent} from './modals/confirm-save-modal.component';
import {ConfirmActionModalComponent} from './modals/confirm-action-modal.component';
import {DropdownFilterComponent} from './components/dropdown-filter/dropdown-filter.component';
import {DatePickerFilterComponentComponent} from './components/date-picker-filter-component/date-picker-filter-component.component';
import {MiscFilterComponent} from './components/misc-filter/misc-filter.component';
import {MobileApprovalsCirclesComponent} from './components/mobile-approvals-circles/mobile-approvals-circles.component';
import {CrewHeaderDetailsViewComponent} from './components/crew-header-details-view/crew-header-details-view.component';
import {UploadTimecardsModalComponent} from './modals/upload-timecards-modal/upload-timecards-modal.component';
import {MobileApprovalBubbleComponent} from './components/mobile-approval-bubble/mobile-approval-bubble.component';
import {RatesPipe} from './pipes/rates.pipe';
import {BatchInvoiceErrorModalComponent} from './modals/batch-invoice-error-modal.component';
import {UploadAttachmentPanelComponent} from './components/upload-attachement-panel/upload-attachment-panel.component';
import {FooterNoteBubbleComponent} from './components/footer-note-bubble/footer-note-bubble.component';
import {DragAndDropDirective} from './components/drag-and-drop/drag-and-drop.directive';
import {ProgressComponent} from './components/progress-bar/progress.component';
import {CrewEntryHeaderComponent} from './components/crew-entry-header/crew-entry-header.component';
import {LoadCrewPanelComponent} from './components/load-crew-panel/load-crew-panel.component';
import {DateFormatPipe} from './pipes/date-format.pipe';
import {SplitModalGlShortcutSelectorComponent} from './components/split-gl-shortcut-selector/split-modal-gl-shortcut-selector.component';
import {BatchDetailsNotePanelComponent} from './components/batch-details-note-panel/batch-details-note-panel.component';
import {DatePickerFilterRegionComponent} from './components/date-picker-filter-region/date-picker-filter-region.component';
import {IRegionUiConfig, RegionUiConfig, RegionUIConfigToken} from '../../../constants/region-ui-config.constants';
import {getState} from './utils';
import {selectProjectRegion} from '../auth/selectors';
import {Region} from '../../../common/constants';
import {WeekEndingDatePickerRegionComponent} from './components/week-ending-date-picker-region/week-ending-date-picker-region.component';
import {DatePickerRegionUtil, DatePickerUtilToken} from './date-picker-region.util';
import {LoaderComponent} from './components/loader/loader.component';
import {CrewDetailsReadonlyTextComponent} from './components/readonly-text-input/crew-details-readonly-text.component';
import {CrewDetailTextInputComponent} from './components/text-input/crew-detail-text-input.component';
import {CrewDetailWorkLocationInputComponent} from './components/work-location-input/crew-detail-work-location-input.component';
import {SagEntryHeaderComponent} from './components/sag-entry-header/sag-entry-header.component';
import {LocationFinderComponent} from './components/location-finder/location-finder.component';
import {WorkStatusSelectorComponent, WtcWorkStatusSelectorComponent} from './components/work-status-selector/work-status-selector.component';
import {WorkLocationFinderButtonComponent} from './components/work-location-finder-button/work-location-finder-button.component';
import {JobCodeDropDownComponent, RerateDropDownComponent, ScheduleCodeDropDownComponent} from './components/rerate-drop-down/rerate-drop-down.component';
import {RerateAmountInputComponent, WtcRerateAmountComponent} from './components/rerate-amount-input/rerate-amount-input.component';
import {CustomDropdownInputComponent, ForceCallCustomDropdownInputComponent} from './components/custom-dropdown-input/custom-dropdown-input.component';
import {NoPhotographCheckboxInput, SmartTimeCheckboxInput} from './components/smart-time-checkbox-input/smart-time-checkbox-input';
import {ShiftLocationFinderButtonComponent} from './components/shift-location-finder-button/shift-location-finder-button.component';
import {LoadStartcardDepartmentPanelComponent, LoadPerformerPanelComponent} from './components/load-startcard-department-panel/load-startcard-department-panel.component';
import {SplitGlButtonComponent} from './components/split-gl-button/split-gl-button.component';
import {SmartTimeSingleSelectionDropdownComponent} from './components/smart-time-single-selection-dropdown/smart-time-single-selection-dropdown.component';
import {
  SmartTimeGlShortcutSelectorComponent,
  WtcGlShortcutSelectorComponent
} from './components/smart-time-gl-shortcut-selector/smart-time-gl-shortcut-selector.component';
import {GlShortcutSelectorComponent} from './components/gl-shortcut-selector/gl-shortcut-selector.component';
import {
  CrewDetailAutocompleteSelectorComponent,
  CrewDetailJobCodeInputComponent,
  CrewDetailScheduleCodeInputComponent
} from './components/crew-detail-autocomplete-selector/crew-detail-autocomplete-selector.component';
import {TimecardLinkButtonColumnComponent} from './components/grid-column-components/timecard-link-button-column.component';
import { DecoupledMobileApprovalLoopComponent } from './components/decoupled-mobile-approval-loop.component';

const setRegionUIConfig = (store: Store): IRegionUiConfig => {
  const region = getState<Region>(store, selectProjectRegion);
  return RegionUiConfig[region];
};

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        RouterModule,
        MaterialModule,
        ScrollingModule,
        ReactiveFormsModule,
        NgxEditorModule,
        NgxMaskModule
    ],
    declarations: [
        FilterPanelComponent,
        TextFilterComponent,
        HandlePopoverCloseDirective,
        SortComponent,
        GridColumnFilterComponent,
        StartCasePipe,
        HighlightPipe,
        GridColumnLoaderComponent,
        LinkButtonColumnComponent,
        TimecardLinkButtonColumnComponent,
        FavoriteStarColumnComponent,
        DefaultGridColumnComponent,
        QuickActionMenuComponent,
        TabOrderDirective,
        AutoTabDirective,
        ManageColumnComponent,
        WeekEndingDatePickerComponent,
        WeekEndingDatePickerRegionComponent,
        TimecardApprovalsCirclesComponent,
        CreateNewMaskComponent,
        DisplayErrorDirective,
        NumericMaskDirective,
        JobSearchComponent,
        PermissionToDisplayDirective,
        PermissionToEnableDirective,
        DeclineTimecardNoteModalComponent,
        TimecardWaitingForApprovalModalComponent,
        TimecardChangedModalComponent,
        DuplicateTimecardModalComponent,
        ChangeDepartmentIdentifierModalComponent,
        ConfirmDeleteTimecardModalComponent,
        ConfirmDeleteBatchModalComponent,
        ConfirmDeleteModalComponent,
        DupNewUserModalComponent,
        ChangingApprovalWorkflowModalComponent,
        ReviseTimecardNoteModalComponent,
        BatchInvoiceErrorModalComponent,
        MaskNumberPipe,
        UnsavedChangesConfirmationModalComponent,
        UploadTimecardsModalComponent,
        ApprovalWorkflowConfigLevelChangeConfirmationComponent,
        PaymasterPickerModalComponent,
        EllipsisPipe,
        DisplaySplitCodeDirective,
        SplitCodingDisplayDeleteDirective,
        SplitCodingScrollDirective,
        CrewPickerComponent,
        WorkLocationPickerComponent,
        EasterEggPopupComponent,
        ConfirmSaveModalComponent,
        ConfirmActionModalComponent,
        DropdownFilterComponent,
        DatePickerFilterComponentComponent,
        MiscFilterComponent,
        MobileApprovalsCirclesComponent,
        CrewHeaderDetailsViewComponent,
        MobileApprovalBubbleComponent,
        RatesPipe,
        UploadAttachmentPanelComponent,
        FooterNoteBubbleComponent,
        GlShortcutSelectorComponent,
        SmartTimeGlShortcutSelectorComponent,
        FooterNoteBubbleComponent,
        DragAndDropDirective,
        ProgressComponent,
        DateFormatPipe,
        ProgressComponent,
        CrewEntryHeaderComponent,
        LoadCrewPanelComponent,
        LoadStartcardDepartmentPanelComponent,
        LoadPerformerPanelComponent,
        SplitModalGlShortcutSelectorComponent,
        BatchDetailsNotePanelComponent,
        DatePickerFilterRegionComponent,
        LoaderComponent,
        CrewDetailsReadonlyTextComponent,
        CrewDetailTextInputComponent,
        CrewDetailWorkLocationInputComponent,
        CrewDetailAutocompleteSelectorComponent,
        CrewDetailJobCodeInputComponent,
        CrewDetailScheduleCodeInputComponent,
        LoaderComponent,
        SagEntryHeaderComponent,
        LocationFinderComponent,
        WorkStatusSelectorComponent,
        WorkLocationFinderButtonComponent,
        RerateDropDownComponent,
        JobCodeDropDownComponent,
        ScheduleCodeDropDownComponent,
        RerateAmountInputComponent,
        WtcRerateAmountComponent,
        SmartTimeSingleSelectionDropdownComponent,
        CustomDropdownInputComponent,
        ForceCallCustomDropdownInputComponent,
        SmartTimeCheckboxInput,
        NoPhotographCheckboxInput,
        ShiftLocationFinderButtonComponent,
        SplitGlButtonComponent,
        WtcGlShortcutSelectorComponent,
        WtcWorkStatusSelectorComponent,
        DecoupledMobileApprovalLoopComponent
    ],
    exports: [
        FilterPanelComponent,
        TextFilterComponent,
        HandlePopoverCloseDirective,
        SortComponent,
        GridColumnFilterComponent,
        StartCasePipe,
        HighlightPipe,
        GridColumnLoaderComponent,
        ManageColumnComponent,
        QuickActionMenuComponent,
        TabOrderDirective,
        AutoTabDirective,
        WeekEndingDatePickerComponent,
        WeekEndingDatePickerRegionComponent,
        TimecardApprovalsCirclesComponent,
        CreateNewMaskComponent,
        DisplayErrorDirective,
        NumericMaskDirective,
        JobSearchComponent,
        DeclineTimecardNoteModalComponent,
        PermissionToDisplayDirective,
        PermissionToEnableDirective,
        TimecardWaitingForApprovalModalComponent,
        TimecardChangedModalComponent,
        DuplicateTimecardModalComponent,
        ChangeDepartmentIdentifierModalComponent,
        ConfirmDeleteTimecardModalComponent,
        ConfirmDeleteBatchModalComponent,
        ConfirmDeleteModalComponent,
        ChangingApprovalWorkflowModalComponent,
        ReviseTimecardNoteModalComponent,
        BatchInvoiceErrorModalComponent,
        MaskNumberPipe,
        UnsavedChangesConfirmationModalComponent,
        UploadTimecardsModalComponent,
        ApprovalWorkflowConfigLevelChangeConfirmationComponent,
        PaymasterPickerModalComponent,
        EllipsisPipe,
        DisplaySplitCodeDirective,
        SplitCodingDisplayDeleteDirective,
        SplitCodingScrollDirective,
        CrewPickerComponent,
        WorkLocationPickerComponent,
        EasterEggPopupComponent,
        DropdownFilterComponent,
        DatePickerFilterComponentComponent,
        MiscFilterComponent,
        MobileApprovalsCirclesComponent,
        CrewHeaderDetailsViewComponent,
        MobileApprovalBubbleComponent,
        RatesPipe,
        UploadAttachmentPanelComponent,
        FooterNoteBubbleComponent,
        SmartTimeGlShortcutSelectorComponent,
        DateFormatPipe,
        CrewEntryHeaderComponent,
        LoadCrewPanelComponent,
        LoadStartcardDepartmentPanelComponent,
        LoadPerformerPanelComponent,
        SplitModalGlShortcutSelectorComponent,
        BatchDetailsNotePanelComponent,
        DatePickerFilterRegionComponent,
        LoaderComponent,
        SagEntryHeaderComponent,
        WorkStatusSelectorComponent,
        DecoupledMobileApprovalLoopComponent,
        CrewDetailJobCodeInputComponent,
        CrewDetailScheduleCodeInputComponent
    ],
    providers: [
        ProjectDetailsDataService,
        TimecardNotesDataService,
        { provide: RegionUIConfigToken, useFactory: setRegionUIConfig, deps: [Store] },
        { provide: DatePickerUtilToken, useClass: DatePickerRegionUtil }
    ]
})
export class SharedModule { }
