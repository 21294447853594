import {PaginationParams} from '../client/app/models/query-params.model';
import {SectionConfig, Sections} from '../client/app/shared/components/manage-column/manage-column.model';

export namespace ApprovalBrowseConstants {
  export class ApprovalBrowse {
    public static get DefaultTimecardPagination(): PaginationParams {
      return {
        offset: 1,
        pageSize: 40
      };
    }

    public static get ApprovalBrowseSortOptions() {
      return {
        accountOrder: 'accountCode[asc];rate[desc];lastName[asc];firstName[asc];jobTitle[asc]',
        name: 'lastName[asc];firstName[asc];jobTitle[asc];periodEndingDate[desc]',
        jobTitle: 'jobTitle[asc];rate[desc];lastName[asc];firstName[asc]',
        weekEnding: 'periodEndingDate[asc];lastName[asc];firstName[asc];jobTitle[asc]',
        department: 'departmentName[asc];lastName[asc];firstName[asc];jobTitle[asc]',
        totalAmount: 'totalAmount[asc];lastName[asc];firstName[asc];jobTitle[asc]',
        hours: 'hours[desc];lastName[asc];firstName[asc];jobTitle[asc]'
      };
    }

    public static get DefaultApprovalSummaryQueryParams() {
      return {
        ...this.DefaultTimecardPagination,
        searchText: '',
        filters: [{
          filterType: 'workflowApprovalStatus',
          operator: '[eq]',
          value: ['ROUTED', 'REROUTED', 'APPROVED']
        }],
        sort: ApprovalBrowse.ApprovalBrowseSortOptions.accountOrder
      };
    }

    public static get CrewColumnConfigs(): SectionConfig {
      return {
        isShowAllColumns: false,
        groupName: 'Crew / Performer',
        section: Sections.crew,
        columns: {
          name: {field: 'name', header: 'Name', isVisible: true, isMandatory: true, ordinal: 1},
          ssn: {field: 'ssn', header: 'SSN', isVisible: true, isMandatory: false, ordinal: 2},
          fein: {field: 'fein', header: 'FEIN', isVisible: true, isMandatory: false, ordinal: 3},
          jobTitle: {field: 'jobTitle', header: 'Job Title', isVisible: false, isMandatory: false, ordinal: 4}
        }
      };
    }

    public static get DetailsColumnConfigs(): SectionConfig {
      return {
        isShowAllColumns: false,
        groupName: 'Details',
        section: Sections.details,
        columns: {
          week: {field: 'week', header: 'Week Ending', isVisible: true, isMandatory: false, ordinal: 1},
          workDays: {field: 'workDays', header: 'Work Days', isVisible: true, isMandatory: false, ordinal: 2},
          batch: {field: 'batch', header: 'Batch', isVisible: true, isMandatory: false, ordinal: 3},
          rerate: {field: 'rerate', header: 'Rerate', isVisible: false, isMandatory: false, ordinal: 4},
          fc: {field: 'fc', header: 'FC', isVisible: false, isMandatory: false, ordinal: 5},
          np: {field: 'np', header: 'NP', isVisible: false, isMandatory: false, ordinal: 6},
          mp: {field: 'mp', header: 'MP', isVisible: false, isMandatory: false, ordinal: 7},
          issues: {field: 'issues', header: 'Issues', isVisible: true, isMandatory: false, ordinal: 8},
          notes: {field: 'notes', header: 'Notes', isVisible: true, isMandatory: false, ordinal: 9},
        }
      };
    }

    public static get SummaryColumnConfigs(): SectionConfig {
      return {
        isShowAllColumns: false,
        groupName: 'Summary',
        section: Sections.summary,
        columns: {
          hours: {field: 'hours', header: 'Hours', isVisible: true, isMandatory: false, ordinal: 1},
          wages: {field: 'wages', header: 'Wages', isVisible: false, isMandatory: false, ordinal: 2},
          other: {field: 'other', header: 'Other', isVisible: false, isMandatory: false, ordinal: 3},
          advances: {field: 'advances', header: 'Advances', isVisible: false, isMandatory: false, ordinal: 4},
          total: {field: 'total', header: 'Total', isVisible: true, isMandatory: false, ordinal: 5},
          approvals: {field: 'approvals', header: 'Approvals', isVisible: true, isMandatory: true, ordinal: 6}
        }
      };
    }

    public static get ApprovalsBrowseMiscFilters(): Readonly<any> {
      // TODO: Uncomment these objects once TC Browse API supports these filters
      return [
        {id: 0, name: 'Forced Call: Yes ', type: 'forceCall', value: 'Yes'},
        {id: 1, name: 'Forced Call: None ', type: 'forceCall', value: 'No'},
        {id: 2, name: 'Night Premium: Yes', type: 'nightPremium', value: 'Yes'},
        {id: 3, name: 'Night Premium: No', type: 'nightPremium', value: 'No'},
        // {name: 'Mobile: Approved', type: 'mobileApproved', value: 'Yes'},
        // {name: 'Mobile: Unapproved ', type: 'mobileApproved', value: 'No'},
      ];
    }
  }
}
