import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, exhaustMap, catchError, tap} from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import {
  RetrieveUserSuccessful,
  RetrieveProjectsSuccessful,
  RetrieveUser, Logout, RetrieveProjects,
} from '../actions/auth';

@Injectable()
export class AuthEffects {

  getUser = createEffect(() => this.actions.pipe(
    ofType(RetrieveUser),
    exhaustMap(() => this.authService.retrieveUser()
      .pipe(
        catchError(error => this.authService.handleAuthError(error)),
        map(userProfile => RetrieveUserSuccessful(userProfile))
      ))
  ));

  logOut = createEffect(() => this.actions.pipe(
    ofType(Logout),
    tap(() => this.authService.logout())
  ), { dispatch: false });

  getProjects = createEffect(() => this.actions.pipe(
    ofType(RetrieveProjects),
    exhaustMap(() => this.authService.retrieveProjects()
      .pipe(
        map(projects => RetrieveProjectsSuccessful(projects))
      ))
  ));

  constructor(
    private actions: Actions,
    private authService: AuthService,
  ) {}

}
