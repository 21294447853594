import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {Paymaster} from '../../../../common/paymaster.model';
import {ProjectDetailsDataService} from '../../shared/data-services/project-details.data.service';
import {PaymasterLookup, PaymasterManagerLookup} from '../actions/paymaster-lookup.action';
import {getState} from '../../shared/utils';
import {selectUserEmail} from '../../auth/selectors';
import {Filters, SetOffsetToDefaultValue} from '../actions/paymaster-ui.action';
import {PaymasterBatchService} from './paymaster-batch.service';
import {FilterOption} from '../../shared/components/filter-panel/filter-item.model';
import {selectIsBTMSearchChanged} from '../selectors/paymaster-ui.selector';

@Injectable()
export class PaymasterLookupResolver implements Resolve<Paymaster[]> {
  constructor(private _store: Store<Paymaster>, private _projectDataService: ProjectDetailsDataService,
              private _paymasterBatchService: PaymasterBatchService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Paymaster[]>|Promise<Paymaster[]>|Paymaster[] {
    return forkJoin(
      this._paymasterBatchService.RetrievePaymasterManagers(),
      this._projectDataService.RetrievePaymasters()
    ).pipe(
      map(([paymasterManagers, paymasters]) => {
        // add paymaster to batch filters
        const isBTMSearchChanged = getState<boolean>(this._store, selectIsBTMSearchChanged);
        if (!isBTMSearchChanged) {
          const paymasterEmail = getState<string>(this._store, selectUserEmail);
          const updateFilter: FilterOption = {
            id: 'paymasters',
            type: 'paymasters',
            value: paymasterEmail
          };
          this._store.dispatch(Filters(updateFilter));
        }
        // reset offset to default value: 1
        this._store.dispatch(SetOffsetToDefaultValue());
        this._store.dispatch(PaymasterManagerLookup(paymasterManagers));
        this._store.dispatch(PaymasterLookup(paymasters));
        return paymasters;
      })
    );
  }
}
