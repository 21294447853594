import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Store} from '@ngrx/store';
import {catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import {Paymaster} from '../../../common/paymaster.model';
import {PaymasterBatchService} from '../paymaster/services/paymaster-batch.service';
import {RetrievePaymanagerSuccessful} from '../actions/lookup.action';

@Injectable({providedIn: 'root'})
export class PaymanagerResolver implements Resolve<Paymaster[]> {
  constructor(private _store: Store<any>, private _paymasterBatchService: PaymasterBatchService) {}

  resolve(): any {
    return this._paymasterBatchService.RetrievePaymasterManagers().pipe(
      catchError(() => {
        return of();
      }),
      tap((paymasters: Paymaster[]) => this._store.dispatch(RetrievePaymanagerSuccessful(paymasters)))
    );
  }
}
