export enum NgbModalName {
  UPLOAD_TIMECARD = 'UPLOAD_TIMECARD',
  TIMECARD_ENTRY_DECLINE_NOTE = 'TIMECARD_ENTRY_DECLINE_NOTE',
  APPROVAL_BROWSE_DECLINE_NOTE = 'APPROVAL_BROWSE_DECLINE_NOTE',
  TIMECARD_CREATE_NEW_CONFIRMATION = 'TIMECARD_CREATE_NEW_CONFIRMATION',
  TIMECARD_DELETE_CONFIRMATION = 'TIMECARD_DELETE_CONFIRMATION',
  TIMECARD_WAITING_FOR_APPROVAL = 'TIMECARD_WAITING_FOR_APPROVAL',
  TIMECARD_REVISE_NOTE = 'TIMECARD_REVISE_NOTE',
  TIMECARD_CHANGED = 'TIMECARD_CHANGED',
  TIMECARD_DIRTY_WARNING = 'TIMECARD_DIRTY_WARNING',
  DEPARTMENT_IDENTIFIER_CHANGED = 'DEPARTMENT_IDENTIFIER_CHANGED',
  CONFIRM_DELETE_TIMECARD = 'CONFIRM_DELETE_TIMECARD',
  STARTCARD_PREVIEW_WARNING = 'STARTCARD_PREVIEW_WARNING',
  SAVE_BEFORE_ASSIGN_BATCH_CONFIRMATION = 'SAVE_BEFORE_ASSIGN_BATCH_CONFIRMATION',
  TIMECARD_UNSAVED_CHANGES_WARNING = 'TIMECARD_UNSAVED_CHANGES_WARNING',
  CONFIRM_DELETE_BATCH = 'CONFIRM_DELETE_BATCH',
  CONFIRM_DELETE = 'CONFIRM_DELETE',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  CHANGING_APPROVAL_WORKFLOW = 'CHANGING_APPROVAL_WORKFLOW',
  USER_MANAGEMENT_DIRTY_CONFIRMATION = 'USER_MANAGEMENT_DIRTY_CONFIRMATION',
  ASSIGN_BATCH = 'ASSIGN_BATCH',
  APPROVAL_WORKFLOW_CONFIG_LEVEL_CHANGE_CONFIRMATION = 'APPROVAL_WORKFLOW_CONFIG_LEVEL_CHANGE_CONFIRMATION',
  SELECT_PAYMASTER = 'SELECT_PAYMASTER',
  DT_ALLOWANCES = 'DT_ALLOWANCES',
  DAILY_TIME_SPLIT_CODING = 'DAILY_TIME_SPLIT_CODING',
  DAILY_TIME_UNSAVED_CHANGES_CONFIRMATION = 'DAILY_TIME_UNSAVED_CHANGES_CONFIRMATION',
  WORK_LOCATION_PICKER = 'WORK_LOCATION_PICKER',
  DAILY_TIME_SPLIT_HANDLING = 'DAILY_TIME_SPLIT_HANDLING',
  DUPLICATE_TIMECARD_EXISTS = 'DUPLICATE_TIMECARD_EXISTS',
  DUPLICATE_TIMECARD_OVERLAY = 'DUPLICATE_TIMECARD_OVERLAY',
  TIMECARD_UNSAVED_WARNING = 'TIMECARD_UNSAVE_OVERLAY',
  PUNCH_DISCREPANCY_OVERLAY = 'PUNCH_DISCREPANCY_OVERLAY',
  BREAKAGE_OVERRIDE_OVERLAY = 'BREAKAGE_OVERRIDE_OVERLAY',
  STARTCARD_DETAILS_UNSAVED_CHANGES_CONFIRMATION = 'STARTCARD_DETAILS_UNSAVED_CHANGES_CONFIRMATION',
  TC_RECALCULATION_CONFIRMATION = 'TC_RECALCULATION_CONFIRMATION',
  DUPLICATE_STARTCARD_EXISTS = 'DUPLICATE STARTCARD EXISTS',
  STARTCARD_INVITE_MOBILE_USER = 'STARTCARD INVITE MOBILE_USER',
  MOBILE_USER_WARNING = 'MOBILE USER WARNING',
  IMPORT_PROJECT_SETTINGS = 'IMPORT PROJECT SETTINGS',
  OVERRIDE_AND_SAVE = 'OVERRIDE AND SAVE',
  CREATE_PROJECT_MODAL = 'CREATE PROJECT MODAL',
  REFRESH_PROJECT_DETAILS_CONFIRMATION = 'REFRESH PROJECT DETAILS CONFIRMATION ',
  BATCH_INJECT = 'BATCH_INJECT',
  UNSUBMIT_BATCH_CONFIRMATION = 'UNSUBMIT BATCH CONFIRMATION',
  VACATION_AND_HOLIDAY = 'VACATION_AND_HOLIDAY',
  BATCH_ENABLE_FOR_IPS = 'BATCH ENABLE FOR IPS',
  ARCHIVED_BATCH_WARNING = 'ARCHIVED BATCH WARNING',
  SUMMARY_LINE_HISTORY = 'SUMMARY LINE HISTORY',
  BATCH_RELEASE = 'BATCH RELEASE',
  BATCH_INVOICE_ERROR = 'BATCH INVOICE ERROR',
  BATCH_RELEASE_SUCCESS = 'BATCH RELEASE SUCCESS',
  BATCH_ACTION_SYNC_LOCK_WARNING = 'BATCH ACTION SYNC LOCK WARNING',
  ACCOUNT_CODING_CONFIRM = 'ACCOUNT_CODING_CONFIRM',
  DEPARTMENT_DETAILS_CONFIRM = 'DEPARTMENT_DETAILS_CONFIRM',
  SPLIT_CODING_MODAL = 'SPLIT CODING MODAL',
  SPLIT_RECALC_ERROR_MODAL = 'SPLIT RECALC ERROR MODAL',
  TIMECARD_DELETE_CONFIRM = 'TIMECARD_DELETE_CONFIRM',
  TEMPLATE_SPLIT_CODING_MODAL = 'TEMPLATE SPLIT CODING MODAL',
  DUPLICATE_BATCH = 'DUPLICATE_BATCH',
  DUPLICATE_MULTIPLE_BATCH = 'DUPLICATE_MULTIPLE_BATCH',
  ERROR_TIMECARD_BATCH= 'ERROR_TIMECARD_BATCH',
  BATCH_ACTION_TIMECARD_ERROR_WARNING = 'BATCH ACTION TIMECARD ERROR WARNING',
  RETURN_TO_CREW_WARNING = 'RETURN TO CREW WARNING',
  UNSUBMIT_TC_CONFIRMATION = 'UNSUBMIT TC CONFIRMATION',
  PAYROLL_BATCH_DETAILS = 'PAYROLL BATCH DETAILS',
  EMAIL_ALREADY_INVITED = 'EMAIL ALREADY INVITED',
  PAYMASTER_DELETE_TC_CONFIRMATION = 'PAYMASTER DELETE TC CONFIRMATION',
  MOBILE_TIMECARD_WEEK_ENDING_CHANGE_WARNING = 'MOBILE TIMECARD WEEK ENDING CHANGE WARNING',
  TIMECARD_WEEK_ENDING_CHANGE_MODAL = 'TIMECARD WEEK ENDING CHANGE MODAL',
  TIMECARD_WEEK_ENDING_DUPLICATE_WARNING = 'TIMECARD WEEK ENDING DUPLICATE WARNING',
  BATCH_EDIT_MODAL = 'Edit Batch',
  STARTCARD_DELETE_CONFIRMATION = 'STARTCARD DELETE CONFIRMATION',
  ROUTE_FOR_APPROVAL = 'ROUTE FOR APPROVAL CONFIRMATION',
  TIMECARD_SUMMARY_FILTER = 'TIMECARD SUMMARY FILTER',
  ACCOUNT_CODING_UPDATE_IN_PROGRESS_WARNING = 'ACCOUNT CODING UPDATE IN PROGRESS WARNING',
  PROJECT_SETTINGS_UPDATE_STARTCARDS_CONFIRMATION = 'PROJECT SETTINGS UPDATE STARTCARDS CONFIRMATION',
  UPDATE_ACCOUNT_CODING = 'UPDATE ACCOUNT CODING',
  UPDATE_ACCOUNT_CODING_UNSAVED_TIMECARD_WARNING = 'UPDATE ACCOUNT CODING UNSAVED TIMECARD_WARNING',
  ROUTE_OR_REROUTE_FOR_APPROVAL = 'ROUTE OR REROUTE FOR APPROVAL CONFIRMATION',
  LOGOUT_CONFIRMATION = 'LOGOUT CONFIRMATION',
  INACTIVE_STARTCARD_WARNING_OVERLAY = 'INACTIVE_STARTCARD_WARNING_OVERLAY'
}
