import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {select, Store} from '@ngrx/store';
import {RouterReducerState} from '@ngrx/router-store';
import {Observable} from 'rxjs';
import {takeWhile} from 'rxjs/operators';

import fontawesome from '@fortawesome/fontawesome';
import {faCog, faFile} from '@fortawesome/fontawesome-free-solid';

import {NoProjectAccountCodesWarningMessage} from '../../shared/constants';
import {User} from '../../../../common/user.model';
import {ImportStartcardService} from '../../shared/data-services/startcard-import.data.service';
import {selectProjectId} from '../selectors';
import {getState} from '../../shared/utils';
import {uuidRegex} from '../../../../common/constants';
import {Permission, ProjectPermissions, ValidatePermission} from '../../../../common/user-profile.model';
import {
  hasAccountCodeConfigSegments,
  selectComputedWorkflowEnabled,
  selectIsChatEnabled, selectIsInternalUser, selectIsSagEnabled,
  selectIsUserEnrollmentPending,
  selectUser
} from '../../auth/selectors';
import {ToastTypes} from '../../models/ui.model';
import {ToastService} from '../../shared/providers/toast.service';
import {TimecardBrowseComponent} from '../../timecard/containers/timecard-browse/timecard-browse.component';
import {StartcardBrowseComponent} from '../../startcard/containers/startcard-browse/startcard-browse.component';
import {BatchBrowseComponent} from '../../batch/containers/batch-browse/batch-browse.component';
import {ApprovalBrowseComponent} from '../../approvals/container/approval-browse/approval-browse.component';
import {DailyTimeComponent} from '../../daily-time/containers/daily-time/daily-time.component';
import {TimecardBrowseGbrComponent} from '../../timecard/containers/timecard-browse-gbr/timecard-browse-gbr.component';
import {SagTimecardBrowseComponent} from '../../timecard/containers/sag/timecard-browse/sag-timecard-browse.component';
declare const liveagent: any;

@Component({
  selector: 'st-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, OnDestroy {
  salesForceChatScriptSrc = 'https://d.la4-c1-ia4.salesforceliveagent.com/chat';    // Prod: https://d.la4-c1-ia4.salesforceliveagent.com/chat,  UAT: https://d.la4-c1cs-ia4.salesforceliveagent.com/chat
  salesForceDeploymentId = '5725d000000fxTE';                                       // Prod: 5725d000000fxTE,  UAT: 5725d000000fxTE
  salesForceOrgId = '00Di0000000g6Hf';                                              // Prod: 00Di0000000g6Hf,  UAT: 00D8N00000050lf
  salesForceButtonId = '5731Y000000k9br';                                           // Prod: 5731Y000000k9br,  UAT: 5731Y000000k9br
  salesForceOnlineButtonId = `liveagent_button_online_${this.salesForceButtonId}`;
  salesForceOfflineButtonId = `liveagent_button_offline_${this.salesForceButtonId}`;

  public permissionTypes = Permission;
  public isChatEnabled: Observable<boolean>;
  public isProjectNavbarDisabled: Observable<boolean>;
  public isWorkflowEnabled: boolean;
  public isSagEnabled: Observable<boolean> = this._store.pipe(
    select(selectIsSagEnabled),
    takeWhile(() => this._isAlive)
  );
  public isInternalUser: Observable<boolean> = this._store.pipe(
    select(selectIsInternalUser),
    takeWhile(() => this._isAlive)
  );
  protected _isAlive = true;
  noProjectAccountCodesWarningMessage = NoProjectAccountCodesWarningMessage;

  constructor(private _router: Router, private _route: ActivatedRoute, private _importStartcardService: ImportStartcardService,
              private _store: Store<RouterReducerState>, private _toastService: ToastService, private _window: Window) {}
  ngOnInit() {
    fontawesome.library.add(faFile, faCog);
    this._store.pipe(
      select(selectComputedWorkflowEnabled),
      takeWhile(() => this._isAlive)
    ).subscribe(isWorkflowEnabled => this.isWorkflowEnabled = isWorkflowEnabled);
    this.isChatEnabled = this._store.pipe(
      select(selectIsChatEnabled),
      takeWhile(() => this._isAlive)
    );
    this.isProjectNavbarDisabled = this._store.pipe(
      select(selectIsUserEnrollmentPending),
      takeWhile(() => this._isAlive)
    );
    this._initializeChat();
  }
  public permissionToEnableStartcardTab: ValidatePermission = (projectPermissions: ProjectPermissions) => {
    if (!projectPermissions?.permissions) {
      return false;
    }
    return projectPermissions.permissions.START_CARD !== Permission.NONE;
  }
  public permissionToEnableBatchesTab: ValidatePermission = (projectPermissions: ProjectPermissions) => {
    if (!projectPermissions?.permissions) {
      return false;
    }
    return projectPermissions.permissions.BATCH_MANAGEMENT !== Permission.NONE;
  }
  public permissionToDisplayApprovalsTab: ValidatePermission = (projectPermissions: ProjectPermissions) => {
    if (!projectPermissions?.permissions) {
      return false;
    }
    return projectPermissions.permissions.TIMECARD_APPROVALS === Permission.FULL && this.isWorkflowEnabled;
  }

  public permissionToEnableTimecardsTab: ValidatePermission = (projectPermissions: ProjectPermissions) => {
    if (!projectPermissions?.permissions) {
      return false;
    }
    return projectPermissions.permissions.TIMECARD !== Permission.NONE;
  }
  public permissionToEnableDailyTimeTab: ValidatePermission = (projectPermissions: ProjectPermissions) => {
    if (!projectPermissions?.permissions) {
      return false;
    }
    return projectPermissions.permissions.DAILY_TIME_SHEET !== Permission.NONE;
  }

  public permissionToEnableUserManagementBtn: ValidatePermission = (projectPermissions: ProjectPermissions) => {
    if (!projectPermissions?.permissions) {
      return false;
    }
    return projectPermissions.permissions.USER_MANAGEMENT !== Permission.NONE;
  }
  // HACK: Angular routerLinkActive doesn't work with the way we setup our routes
  // and they aren't address it https://github.com/angular/angular/issues/13205
  isFeaturesActive(featureName: string) {
    return RegExp(`^/projects/${uuidRegex}/${featureName}/.`).test(this._router.url)
      ? 'st-nav-link-active'
      : '';
  }

  editTemplate() {
    this._router.navigate(['templates/timecard/default'], {relativeTo: this._route});
  }

  projectSettings() {
    this._router.navigate(['settings'], {relativeTo: this._route});
  }

  help() {
    this._router.navigate(['help'], {relativeTo: this._route});
  }

  importStartcard() {
    const hasAccountCodes = getState<boolean>(this._store, hasAccountCodeConfigSegments);
    if (!hasAccountCodes) {
      this._toastService.flash('importStartcardFail', ToastTypes.Error, NoProjectAccountCodesWarningMessage);
      return;
    }
    const projectId = getState<string>(this._store, selectProjectId);
    this._importStartcardService.ImportStartcards(projectId).pipe(
      takeWhile(() => this._isAlive)
    ).subscribe((importStatus) => {
      if (importStatus) {
        this._toastService.flash(`InitiatedStartcardImport`, ToastTypes.Success, `Import Start Cards Initiated`);
        return;
      }
      this._toastService.flash(`StartcardImportFailed`, ToastTypes.Error, `Import Start Card Failed. Please try again`);
    });
  }

  userManagement() {
    this._router.navigate(['user-management'], {relativeTo: this._route});
  }

  ngOnDestroy(): void {
    this._isAlive = false;
  }

  startSupportChat() {
    liveagent.startChat(this.salesForceButtonId);
  }

  private _initializeChat() {
    liveagent.init(this.salesForceChatScriptSrc, this.salesForceDeploymentId, this.salesForceOrgId);
    // add login user's name into the chat box
    const {firstName, lastName, email} = getState<User>(this._store, selectUser);
    liveagent.addCustomDetail('ContactFirstName', firstName);
    liveagent.addCustomDetail('ContactlastName', lastName);
    liveagent.addCustomDetail('ContactEmail', email);
    if (!(<any>this._window)._laq) { (<any>this._window)._laq = []; }
    (<any>this._window)._laq.push(() => {
      liveagent.showWhenOnline(this.salesForceButtonId, document.getElementById(this.salesForceOnlineButtonId));
      liveagent.showWhenOffline(this.salesForceButtonId, document.getElementById( this.salesForceOfflineButtonId));
    });
  }
  // shows no account coding for projects warning banner in child components
  onRouteLoaded(
    childComponent: TimecardBrowseComponent|TimecardBrowseGbrComponent|StartcardBrowseComponent|DailyTimeComponent|BatchBrowseComponent|ApprovalBrowseComponent
      |SagTimecardBrowseComponent,
    warningBannerTemplate: TemplateRef<any>
  ) {
    childComponent.noProjectAccountCodesWarningBanner = warningBannerTemplate;
    childComponent.hasProjectAccountCodes = this._store.pipe(
      select(hasAccountCodeConfigSegments),
      takeWhile(() => this._isAlive)
    );
  }
}
