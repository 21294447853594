import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {TaxJurisdictionLookupService} from '../shared/providers/tax-jurisdiction-lookup.service';
import {Store} from '@ngrx/store';

@Injectable({providedIn: 'root'})
export class TaxJurisdictionLookupResolver implements Resolve<any> {
  constructor(private _store: Store<any>, private _taxJurisdictionLookupService: TaxJurisdictionLookupService) {}

  resolve(): any {
    this._taxJurisdictionLookupService.RetrieveTaxJurisdiction().subscribe();
  }
}

