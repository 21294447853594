import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import {Store} from '@ngrx/store';

import {AuthService} from './auth.service';
import {RetrieveClientConfigSuccessful} from '../actions/auth';
import {ResetProjectUiConfig} from '../../actions/ui.action';

/**
 * AppInitLocalStorageService
 * Set the version of the app into local storage to determine whether clear local storage is needed.
 * Only clear and reload the config when retrieveClientConfig returns value (not 401) and update the config.
 * */

@Injectable({providedIn: 'root'})
export class AppInitLocalStorageService {
  constructor(private _store: Store, private _authService: AuthService) {}
  initializeLocalStorage(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      // retrieve Client Config and apply it to the store. (this requires user to be logged in), so the initial call b4 auth page will fail every time.
      const {
        appVersion,
        isSaleForceChatEnabled,
        addDayTypeClientIds,
        documentCategories,
        dayCostReportChunkSize,
        sagEnabledClientIds,
        smartIdLogoutUrl
      } = await this._authService.retrieveClientConfig().toPromise() ?? {};
      if (_.isUndefined(appVersion)) {
        console.log(`[InitializeLocalStorage] failed to retrieve client config`);
        return resolve(false);
      }
      this._store.dispatch(RetrieveClientConfigSuccessful({
        appVersion, isSaleForceChatEnabled, addDayTypeClientIds, documentCategories, dayCostReportChunkSize, sagEnabledClientIds, smartIdLogoutUrl
      }));
      const localAppVersion = localStorage.getItem('appVersion') ?? '';
      console.log(`[InitializeLocalStorage] AppVersion: ${appVersion}, localStorage.getItem('appVersion'): ${localAppVersion}`);
      // If there is no version or when version in local storage does not match the package.json version, clear local storage
      if (!_.isEqual(localAppVersion, appVersion)) {
        this._store.dispatch(ResetProjectUiConfig());
        localStorage.clear();
        sessionStorage.clear();
        // update appVersion with the one from config
        localStorage.setItem('appVersion', appVersion);
        console.log(`[InitializeLocalStorage] Updated App Version ${localStorage.getItem('appVersion')}`);
      }
      resolve(true);
    });
  }
}
