<div [hidden]="isProjectNavbarDisabled|async">
  <div class="navigation">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <div class="collapse navbar-collapse" id="navbarText">
          <ul class="navbar-nav me-auto left">
            <li *ngIf="!(isSagEnabled| async)" class="nav-item" [ngClass]="isFeaturesActive('timecards')">
              <a class="nav-link" qaa="mainNavTcs" [stPermissionToEnable]="permissionToEnableTimecardsTab" routerLink="timecards/browse">TIMECARDS</a>
            </li>
            <li *ngIf="(isSagEnabled| async)" class="nav-item" ngbDropdown placement="bottom-right" [ngClass]="isFeaturesActive('timecards')" #dropDownTimecards="ngbDropdown" (mouseover)='dropDownTimecards.open()'>
              <a ngbDropdownToggle (mouseout)='dropDownTimecards.close()' class="nav-link" qaa="mainNavTcs" [stPermissionToEnable]="permissionToEnableTimecardsTab">TIMECARDS</a>
                <mat-icon (mouseout)='dropDownTimecards.close()' class="nav-dropdown-icon">keyboard_arrow_down</mat-icon>
              <div ngbDropdownMenu (mouseout)='dropDownTimecards.close()' class="user-type-container">
                <button ngbDropdownItem routerLink="timecards/browse">CREW</button>
                <button ngbDropdownItem routerLink="timecards/sag/browse">SAG-AFTRA</button>
              </div>
            </li>
            <li class="nav-item" [ngClass]="isFeaturesActive('startcards')">
              <a class="nav-link" qaa="mainNavStartcards" [stPermissionToEnable]="permissionToEnableStartcardTab" routerLink="startcards">STARTCARDS</a>
            </li>
            <li *ngIf="!(isSagEnabled| async)" class="nav-item" routerLinkActive="st-nav-link-active">
              <a class="nav-link" qaa="mainNavDailyTime" [stPermissionToEnable]="permissionToEnableDailyTimeTab" routerLink="daily-times">DAILY TIME</a>
            </li>
            <li *ngIf="isSagEnabled| async" class="nav-item" ngbDropdown placement="bottom-right" #dropDownDailyTime="ngbDropdown" (mouseover)='dropDownDailyTime.open()' routerLinkActive="st-nav-link-active">
              <a ngbDropdownToggle (mouseout)='dropDownDailyTime.close()' class="nav-link" qaa="mainNavDailyTime" [stPermissionToEnable]="permissionToEnableDailyTimeTab">DAILY TIME</a>
              <mat-icon class="nav-dropdown-icon" (mouseout)='dropDownDailyTime.close()'>keyboard_arrow_down</mat-icon>
              <div ngbDropdownMenu (mouseout)='dropDownDailyTime.close()' class="user-type-container">
                <button ngbDropdownItem routerLink="daily-times">CREW</button>
                <button ngbDropdownItem routerLink="daily-times/sag">SAG-AFTRA</button>
              </div>
            </li>
            <li class="nav-item" [ngClass]="isFeaturesActive('batches')">
              <a class="nav-link" qaa="mainNavBatches" [stPermissionToEnable]="permissionToEnableBatchesTab" routerLink="batches/browse">BATCHES</a>
            </li>
            <li class="nav-item" routerLinkActive="st-nav-link-active" *stPermissionToDisplay="permissionToDisplayApprovalsTab">
              <a class="nav-link" qaa="mainNavApprovals" routerLink="approvals/browse">APPROVALS</a>
            </li>
          </ul>
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li [hidden]="!(isChatEnabled|async)">
              <button id="liveagent_button_online_5731Y000000k9br" style="display: none;" class="btn btn-sf-chat" (click)="startSupportChat()"><i class="fas fa-comment-alt"></i> Live Chat</button>
              <button id="liveagent_button_offline_5731Y000000k9br" style="display: none;" class="btn btn-sf-chat" (click)="startSupportChat()"><i class="fas fa-comment-alt"></i> Live Chat</button>
            </li>
            <li class="nav-item" routerLinkActive="st-nav-link-active">
            <st-advanced-reports></st-advanced-reports>
            </li>
            <li class="nav-item" routerLinkActive="st-nav-link-active">
              <div class="btn-group settings-menu" ngbDropdown role="group" aria-label="settings dropdown menu">
                <button class="btn btn-settings" id="settingsMenuBtn" qaa="userAccountMenuBtn" ngbDropdownToggle><i class="fas fa-cog"></i> Settings</button>
                <div class="dropdown-menu st-fs-14" ngbDropdownMenu>
                  <button id="helpBtn" qaa="helpBtn" class="dropdown-item" (click)="help()">Help</button>
                  <button id="settingsBtn" qaa="settingsBtn" (click)="projectSettings()" class="dropdown-item">Project Settings</button>
                  <button id="userMangementBtn" qaa="userManagementBtn" class="dropdown-item" [stPermissionToEnable]="permissionToEnableUserManagementBtn" (click)="userManagement()">User Management</button>
                  <button *ngIf="!(isSagEnabled| async)" id="templateBtn" qaa="templateBtn" class="dropdown-item" (click)="editTemplate()">Template</button>
                  <button [disabled]="!(isInternalUser| async)" id="importStartcardBtn" qaa="importStartcardBtn" class="dropdown-item" (click)="importStartcard()">Update Starts from EP</button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</div>

<router-outlet (activate)="onRouteLoaded($event, noProjectAccountCodesWarningBanner)"></router-outlet>

<!-- No project account codes warning banner -->
<ng-template #noProjectAccountCodesWarningBanner>
  <div class="mt-2">
    <div class="st-banner st-banner-warning pl" qaa="noAccountCodingForProjectBanner">
      <i class="st-warning-icon"></i> <span qaa="noAccountCodingForProjectTitle" class="st-banner-label"> Project Warning</span>
      <span qaa="noAccountCodingForProjectMessage" class="st-banner-description">{{noProjectAccountCodesWarningMessage}}</span>
    </div>
  </div>
</ng-template>
